/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable indent */

export default function MeetingInfo({ setMeetingInfo }) {
    const copyLink = () => {
        navigator.clipboard.writeText(window.location.href);
    };
    return (
        <>
            <div className="p-4 w-full ">
                <div>
                    <p className="text-left cursor-pointer" onClick={() => setMeetingInfo(false)}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M9 9L15 15M15 9L9 15M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg>
                    </p>
                </div>
                <h2 className="text-center text-[#475467] text-xl">Meeting Info</h2>

                <div className="mt-2">
                    <p className="text-left text-[#475467] font-bold">Joining Info </p>
                    <p className="text-center text-[#475467] p-3 bg-gray-300 rounded-lg my-2 break-all">{window.location.href}</p>
                    <button className="text-center text-white p-2 bg-black rounded-lg" onClick={copyLink}>Copy Link</button>
                </div>
            </div>
        </>
    );
}
