import axios from 'axios';
import Config from '../config';

const getMeetingHistory = (page, limit, filter, search) => {
  return axios({
    method: 'get',
    url: `${Config.url || ''}/api/v1/user/meetings_history?date=${new Date().toLocaleDateString('en-CA')}${page ? `&page=${page}` : ''}${limit ? `&limit=${limit}` : ''}${filter ? `&${filter}` : ''}${search ? `&search=${search}` : ''}`,
  });
};

export default getMeetingHistory;
