import { Button } from '../../../components/ui/button';
import InvoiceTable from './invoice-table';

function InvoiceTableContainer() {
  return (
    <div className="space-y-6">
      <div className="flex justify-between">
        <div>
          <h2 className="text-gray-900 text-lg font-semibold leading-7 mb-2">Billing and invoicing</h2>
          <p className="text-slate-600 text-sm font-normal leading-tight m-0">
            Pick an account plan that fits your workflow.
          </p>
        </div>
        <div className="flex gap-3">
          <Button className="bg-white border hover:bg-gray-50 space-x-2">
            <img src="/image/icons/download-cloud.svg" alt="" />
            <span className="text-slate-700 text-sm font-semibold leading-tight">Download all</span>
          </Button>

          <Button className="bg-white hover:bg-white">
            <img src="/image/icons/dots-vertical.svg" className="w-5 h-5" alt="" />
          </Button>
        </div>
      </div>
      <InvoiceTable />
    </div>
  );
}

export default InvoiceTableContainer;
