import { getGravatarUrl } from '../lib/utils';
import Config from '../config';

function Picture({
  user = {},
}) {
  if (user.picture) return <img src={`${Config.url || ''}/${user?.picture?.location}`} className="w-8 h-8" alt="Picture" />;
  return (
    <img src={getGravatarUrl(`${user?.firstName} ${user?.lastName}`, user?.email, 300)} alt="Picture" />
  );
}

export default Picture;
