/* eslint-disable indent */
/* eslint-disable react/jsx-first-prop-new-line */
/* eslint-disable react/jsx-max-props-per-line */
import {
  Pagination, PaginationContent,
  PaginationItem,
  PaginationNext, PaginationPrevious,
} from '../../../components/ui/pagination';
import ContactItem from './contact-item';

function ContactListing({
  contacts, search, selectedContact, setSelectedContact, page, setPage,
}) {
  const lowercasedSearch = search.toLowerCase();
  const filteredContacts = contacts
    ?.filter((item) => {
      if (lowercasedSearch === '') {
        return true;
      }

      const {
        firstName, lastName, email, phone, _id,
      } = item.contact_user || {};

      return (
        (firstName && firstName.toLowerCase().includes(lowercasedSearch))
        || (lastName && lastName.toLowerCase().includes(lowercasedSearch))
        || (email && email.toLowerCase().includes(lowercasedSearch))
        || (phone && phone.includes(search))
        || (_id && _id.toLowerCase().includes(lowercasedSearch))
      );
    });
  return (
    <div className="space-y-6 w-full">
      <div className="space-y-4 max-h-[55vh] overflow-y-scroll scroll-none">
        {filteredContacts?.map((item, i) => (
          <ContactItem key={i} contact={item} setSelectedContact={setSelectedContact} selectedContact={selectedContact} />
        ))}

        {
          filteredContacts.length === 0 && 'No contacts found.'
        }
      </div>

      {
        filteredContacts?.length >= 10 && (
          <Pagination>
            <PaginationContent>
              <PaginationItem>
                <PaginationPrevious onClick={() => setPage((prev) => (prev < 1 ? 1 : prev - 1))} className={page === 1 ? 'text-gray-500 cursor-not-allowed' : 'text-gray-800'} />
              </PaginationItem>
              <PaginationItem>
                <PaginationNext
                  onClick={() => setPage((prev) => (filteredContacts?.length === 0 ? prev : prev + 1))}
                  className={((filteredContacts?.length === 0 && page > 1) || filteredContacts?.length < 10) ? 'text-gray-500 cursor-not-allowed' : 'text-gray-800'}
                />
              </PaginationItem>
            </PaginationContent>
          </Pagination>
        )
      }

    </div>
  );
}

export default ContactListing;
