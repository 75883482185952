/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable new-cap */
/* eslint-disable no-else-return */
/* eslint-disable react/function-component-definition */
import { useState, useEffect } from 'react';
import { Tooltip } from 'react-tooltip';

const BriefRevisions = ({ versions }) => {
  const [activeVersion, setActiveVersion] = useState(versions.length - 1); // Latest version
  const [content, setContent] = useState('');

  useEffect(() => {
    if (versions[activeVersion]) {
      renderVersion(versions[activeVersion]);
    }
  }, [activeVersion]);

  // Renders the content of the active version with highlighted changes
  const renderVersion = (version) => {
    let renderedContent = '';
    version.changes.forEach((change) => {
      if (change?.added) {
        renderedContent += `<span class="bg-green-200 hover:bg-green-300" data-tooltip-id="tooltip" data-tooltip-content="${
          version.editor?.firstName
        } at ${new Date(version.createdAt).toLocaleString()}">${change.value}</span>`;
      } else if (change.removed) {
        renderedContent += `<span class="line-through bg-red-200 hover:bg-red-300" data-tooltip-id="tooltip" data-tooltip-content="${
          version.editor?.firstName
        } at ${new Date(version.createdAt).toLocaleString()}">${change.value}</span>`;
      } else if (change.changeType === 'modification') {
        renderedContent += `<span class="bg-yellow-200 hover:bg-yellow-300" data-tooltip-id="tooltip" data-tooltip-content="${
          version.editor?.firstName
        } at ${new Date(version.createdAt).toLocaleString()}">${change.value}</span>`;
      } else {
        renderedContent += change.value; // Unchanged content
      }
    });
    setContent(renderedContent);
  };

  return (
    <div className="p-4">
      <div className="mb-4">
        <h3 className="text-lg font-bold">Brief Revisions</h3>
        <select className="border p-2 rounded" value={activeVersion} onChange={(e) => setActiveVersion(e.target.value)}>
          {versions.map((version, index) => (
            <option key={version.version} value={index}>
              Version {version.version} - {new Date(version.createdAt).toLocaleString()}
            </option>
          ))}
        </select>
      </div>
      <div className="content" dangerouslySetInnerHTML={{ __html: content }} />
      <Tooltip id="tooltip" effect="solid" delayShow={100} place="top" /> {/* Initialize Tooltip here */}
    </div>
  );
};

export default BriefRevisions;
