/* eslint-disable object-curly-newline */
// import { useParams } from 'react-router-dom';
// import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useGlobal } from 'reactn';
import Interface from './Interface';
import './Streams.sass';
import RoomCaptions from './RoomCaptions';

function Streams({
  streams, children, isMaximized, isGrid,
}) {
  const consumers = useSelector((state) => state.rtc.consumers);
  const producers = useSelector((state) => state.rtc.producers);
  const peers = useSelector((state) => state.rtc.peers);
  const socketID = useSelector((state) => state.io.id);
  const [mainStream, setMainStream] = useGlobal('mainStream');
  const [audioStream] = useGlobal('audioStream');
  const [localStream] = useGlobal('localStream');
  const [user] = useGlobal('user');
  const pinned = useGlobal('pinned')[0];
  const [showCaption] = useGlobal('showCaption');

  const actualConsumers = consumers.filter((c) => c !== socketID);
  let actualPeers = [];
  actualConsumers.forEach((consumerID) => {
    const actualPeer = {
      ...peers[consumerID],
      video: null,
      audio: null,
      screen: null,
    };
    const peerStreams = streams.filter((s) => s.socketID === consumerID);
    peerStreams.forEach((stream) => {
      actualPeer.streams = [...(actualPeer.streams || []), stream];
      if (stream.isVideo) return (actualPeer.video = stream);
      actualPeer.audio = stream;
    });
    const isScreen = (actualPeer.video || actualPeer.screen)
      && producers.filter((p) => p.producerID === actualPeer.video.producerID && p.isScreen).length > 0;
    actualPeers.push({ ...actualPeer, isScreen });
  });

  if (!isGrid && !mainStream && actualPeers.length > 0) {
    setMainStream(actualPeers[actualPeers.length - 1]);
  }
  actualPeers = actualPeers.filter((peer) => peer.approveJoin === true).sort((a) => (((a.audio !== null) || (a.screen !== null) || (a.video !== null)) ? -1 : 1));

  actualPeers.push({ socketID: 'currentUser', video: localStream?.active ? localStream : null, audio: audioStream?.active, user, pinned });

  let getOnlyVideoPeer = actualPeers.find((p) => (p.video && p?.pinned));

  if (!getOnlyVideoPeer) {
    getOnlyVideoPeer = actualPeers.find((p) => (p.video));
  }

  actualPeers = actualPeers?.filter((peer) => getOnlyVideoPeer?.socketID !== peer.socketID);

  if (!isGrid && mainStream && actualPeers.length > 0) {
    let mainPeer = mainStream;
    actualPeers.forEach((peer) => peer.socketID === mainPeer && (mainPeer = peer));
    return (
      <div className="streams uk-flex uk-flex-middle uk-flex-center uk-flex-column">
        <div className="video-container">
          <div className="video-row">
            <div className="video-wrapper">
              <Interface
                isMaximized={isMaximized}
                video={mainPeer.video}
                audio={mainPeer.audio}
                peer={mainPeer.user}
                isScreen={mainPeer.isScreen}
                socketID={mainPeer.socketID}
              />
            </div>
          </div>
        </div>
        {children}
      </div>
    );
  }

  const totalPeers = actualPeers.length;
  const gridCols = totalPeers > 6 ? 4 : totalPeers > 4 ? 3 : totalPeers > 1 ? 2 : 1; // Calculate columns based on total peers
  // const gridRows = Math.ceil(totalPeers / gridCols); // Calculate rows based on columns
  const rows = [];
  const row = [];

  const maxEl = 5;

  actualPeers.slice(0, maxEl).forEach((peer, key) => {
    row.push(
      <div
        className="video-wrapper bg-gray-700 rounded-xl w-full h-full"
        key={key}
      >
        <Interface
          isMaximized={isMaximized}
          video={peer.video}
          audio={peer.audio}
          peer={peer.user}
          isScreen={peer.isScreen}
          socketID={peer.socketID}
          pinned={peer.pinned}
        />
      </div>,
    );
  });

  if (actualPeers.length > maxEl) {
    row.push(
      <div className="video-wrapper h-full " key={6}>
        <div className="interface rounded-xl overflow-hidden uk-flex uk-flex-middle uk-flex-center uk-flex-column uk-height-1-1 max-w-[385px] mx-auto h-full bg-red-200 bg-opacity-50 ">
          <div className="w-full h-full flex items-center justify-center text-7xl text-white drop-shadow p-6">
            +
            {actualPeers.length - maxEl}
          </div>
        </div>
      </div>,
    );
  }

  if (row.length > 0) {
    rows.push(
      <div
        className={`video-row h-full flex-[2] grid grid-cols-${gridCols} gap-2`} // Adjust dynamic grid columns
        key="last"
      >
        {row}
      </div>,
    );
  }

  return (
    <div className="streams uk-flex uk-flex-middle uk-flex-center uk-flex-column mb-20 transition-all duration-300 ease-in">
      {/* {actualPeers.length === 0 && !getOnlyVideoPeer && <p className="waiting">Waiting for others to join...</p>} */}

      <div className="video-container my-4">
        {getOnlyVideoPeer && (
          <div className="video-row flex-[3] h-full grid grid-cols-0" key="last">
            <div className="video-wrapper bg-gray-700 rounded-xl w-full">
              <Interface
                isMaximized={isMaximized}
                video={getOnlyVideoPeer.video}
                audio={getOnlyVideoPeer.audio}
                peer={getOnlyVideoPeer.user}
                isScreen={getOnlyVideoPeer.isScreen}
                socketID={getOnlyVideoPeer.socketID}
                pinned={getOnlyVideoPeer.pinned}
              />
            </div>
          </div>
        )}
        {rows}
      </div>
      {children}
      {
        showCaption && (
          <div className="mb-2 flex justify-center items-center w-full">
            <RoomCaptions />
          </div>
        )
      }
    </div>
  );
}

export default Streams;
