import axios from 'axios';
import Config from '../config';

const getWorkspaces = () => {
  return axios({
    method: 'get',
    url: `${Config.url || ''}/api/v1/user/workspace/workspaces`,
  });
};

export default getWorkspaces;
