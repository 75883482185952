/* eslint-disable object-curly-newline */
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useGlobal } from 'reactn';
import Config from '../../../config';
import { getGravatarUrl } from '../../../lib/utils';
import './Interface.sass';

function Interface({
  audio, video, peer, isMaximized, isScreen, socketID, pinned,
}) {
  const audioRef = useRef(null);
  const videoRef = useRef(null);
  const io = useSelector((state) => state.io.io);
  const user = useGlobal('user')[0];
  const meeting = useGlobal('meeting')[0];
  const setPinned = useGlobal('pinned')[1];

  useEffect(() => {
    if (socketID === 'currentUser') return;
    if (!audio) return;
    if (audio) audioRef.current.srcObject = audio;
  }, [audio]);

  useEffect(() => {
    if (!video) return;
    if (video) videoRef.current.srcObject = video;
  }, [video]);

  const handlePin = async () => {
    await io.request('pin_peer', { roomID: meeting?.meeting?._id, userID: user?._id, peer, pinned: !pinned });
    setPinned(!pinned);
  };

  return (
    <div className={`interface uk-flex uk-flex-middle uk-flex-center uk-flex-column h-full ${video ? '' : 'max-w-[100%] max-h-[100%]'} mx-auto`}>
      {audio && socketID !== 'currentUser' && (
        <audio
          ref={audioRef}
          onLoadedMetadata={() => audioRef.current.play()}
          className="remote-audio"
          controls={false}
          hidden
          data-user={peer}
        />
      )}
      {video && (
        <div className="size-full bg-red-200 bg-opacity-50 flex-grow bg-center bg-cover bg-no-repeat rounded-xl flex flex-col justify-end relative overflow-hidden">
          <video
            ref={videoRef}
            onLoadedMetadata={() => videoRef.current.play()}
            className="size-full bg-red-200 bg-opacity-50"
            playsInline
            controls={false}
            hidden={false}
            data-user={peer}
            style={{ objectFit: !isMaximized || isScreen ? 'contain' : 'cover' }}
          />

          <div className="absolute bottom-4 right-6 flex items-center gap-2">
            <button
              className={`bg-[#173300] hover:bg-[#173310] text-[#ffed74] font-bold py-2 px-4 rounded ${socketID !== 'currentUser' ? 'pointer-events-none' : ''}`}
              onClick={() => {
                if (socketID === 'currentUser') {
                  handlePin();
                }
              }}
            >
              {
                pinned ? (
                  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="SVGRepo_bgCarrier" strokeWidth="0" />
                    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
                    <g id="SVGRepo_iconCarrier">
                      <path d="M19.1835 7.80516L16.2188 4.83755C14.1921 2.8089 13.1788 1.79457 12.0904 2.03468C11.0021 2.2748 10.5086 3.62155 9.5217 6.31506L8.85373 8.1381C8.59063 8.85617 8.45908 9.2152 8.22239 9.49292C8.11619 9.61754 7.99536 9.72887 7.86251 9.82451C7.56644 10.0377 7.19811 10.1392 6.46145 10.3423C4.80107 10.8 3.97088 11.0289 3.65804 11.5721C3.5228 11.8069 3.45242 12.0735 3.45413 12.3446C3.45809 12.9715 4.06698 13.581 5.28476 14.8L6.69935 16.2163L2.22345 20.6964C1.92552 20.9946 1.92552 21.4782 2.22345 21.7764C2.52138 22.0746 3.00443 22.0746 3.30236 21.7764L7.77841 17.2961L9.24441 18.7635C10.4699 19.9902 11.0827 20.6036 11.7134 20.6045C11.9792 20.6049 12.2404 20.5358 12.4713 20.4041C13.0192 20.0914 13.2493 19.2551 13.7095 17.5825C13.9119 16.8472 14.013 16.4795 14.2254 16.1835C14.3184 16.054 14.4262 15.9358 14.5468 15.8314C14.8221 15.593 15.1788 15.459 15.8922 15.191L17.7362 14.4981C20.4 13.4973 21.7319 12.9969 21.9667 11.9115C22.2014 10.826 21.1954 9.81905 19.1835 7.80516Z" fill="#ffffff" />
                    </g>
                  </svg>
                ) : (
                  <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="SVGRepo_bgCarrier" strokeWidth="0" />
                    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
                    <g id="SVGRepo_iconCarrier">
                      <path d="M17.1218 1.87023C15.7573 0.505682 13.4779 0.76575 12.4558 2.40261L9.75191 6.73289L11.1969 8.17793C11.2355 8.1273 11.2723 8.07415 11.3071 8.01845L14.1523 3.46191C14.493 2.91629 15.2528 2.8296 15.7076 3.28445L20.6359 8.21274C21.0907 8.66759 21.0041 9.42737 20.4584 9.76806L15.9019 12.6133C15.8462 12.6481 15.793 12.6848 15.7424 12.7234L17.1874 14.1684L21.5177 11.4645C23.1546 10.4424 23.4147 8.16307 22.0501 6.79852L17.1218 1.87023Z" fill="#ffffff" />
                      <path d="M3.56525 8.85242C3.6015 8.26612 3.84962 7.68582 4.32883 7.27422L5.77735 8.72274C5.75784 8.72967 5.73835 8.7368 5.71886 8.74414C5.64516 8.7719 5.61855 8.80285 5.60548 8.82181C5.58877 8.84604 5.56651 8.8937 5.56144 8.97583C5.55046 9.15333 5.62872 9.40686 5.82846 9.6066L14.3137 18.0919C14.5135 18.2916 14.767 18.3699 14.9445 18.3589C15.0266 18.3538 15.0743 18.3316 15.0985 18.3149C15.1175 18.3018 15.1484 18.2752 15.1762 18.2015C15.1835 18.182 15.1907 18.1625 15.1976 18.143L16.6461 19.5915C16.2345 20.0707 15.6542 20.3188 15.0679 20.3551C14.2853 20.4035 13.4808 20.0874 12.8995 19.5061L9.36397 15.9705L2.68394 22.6506C2.29342 23.0411 1.66025 23.0411 1.26973 22.6506C0.879206 22.26 0.879206 21.6269 1.26973 21.2363L7.94975 14.5563L4.41425 11.0208C3.83293 10.4395 3.51687 9.63502 3.56525 8.85242Z" fill="#ffffff" />
                      <path d="M2.00789 2.00786C1.61736 2.39838 1.61736 3.03155 2.00789 3.42207L20.5862 22.0004C20.9767 22.3909 21.6099 22.3909 22.0004 22.0004C22.391 21.6099 22.391 20.9767 22.0004 20.5862L3.4221 2.00786C3.03158 1.61733 2.39841 1.61733 2.00789 2.00786Z" fill="#ffffff" />
                    </g>
                  </svg>
                )

              }
            </button>
            <button
              className="bg-[#173300] hover:bg-[#173310] text-[#ffed74] font-bold py-2 px-4 rounded"
              onClick={() => videoRef.current.requestFullscreen()}
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15" />
              </svg>
            </button>
          </div>
        </div>
      )}
      {!video && (
        <div className="flex justify-center items-center relative overflow-hidden p-4">
          {
            peer?.picture ? <img src={`${Config.url || ''}/${peer.picture.location}`} alt="Picture" className="h-[200px] w-[200px] bg-red-200 bg-opacity-50 bg-center  rounded-full" /> : <img src={getGravatarUrl(`${peer?.firstName} ${peer?.lastName}`, peer?.email, 300)} alt="Picture" className="h-[200px] w-[200px] bg-red-200 bg-opacity-50 bg-center  rounded-full" />
          }
        </div>
      )}
      {
        audio && (
          <div className="w-full h-full absolute top-0 right-0 p-4">
            <div className="p-2 px-4 bg-gray-200 rounded-lg justify-end items-end gap-1.5 inline-flex">
              {/* <div className="w-6 h-6 relative" /> */}
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="microphone-02">
                  <path id="Icon" d="M20 12V13C20 17.4183 16.4183 21 12 21C7.58172 21 4 17.4183 4 13V12M12 17C9.79086 17 8 15.2091 8 13V7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7V13C16 15.2091 14.2091 17 12 17Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </g>
              </svg>
            </div>
          </div>
        )
      }

      <div className="absolute bottom-4 left-4 text-gray-900 text-base font-semibold leading-normal text-end bg-white p-2 px-3 rounded-lg">
        {
          socketID === 'currentUser' ? (
            <>
              You
            </>
          )
            : (
              <>
                {peer?.firstName}
                {' '}
                {peer?.lastName}
              </>
            )
        }
      </div>
    </div>
  );
}

export default Interface;
