/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import moment from 'moment';
import {
  Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle,
} from '../../../components/ui/dialog';
import { convertTo12Hour } from '../../../lib/utils';

function BookingCancelledStatusModal({
  open,
  setOpen,
  data,
  date = '',
  time = '',
  timeZone = '',
}) {
  return (
    <Dialog open={open} onOpenChange={setOpen} className="">
      <DialogContent className="bg-[#EAECF0] max-w-[641px]">
        <DialogHeader>
          <DialogTitle className="hidden" />
          <DialogDescription className="hidden" />
        </DialogHeader>
        <div className="flex flex-col items-center justify-center gap-3 ">
          <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24Z" fill="#D92D2015" />
            <path d="M19.5 24L22.5 27L28.5 21M34 24C34 29.5228 29.5228 34 24 34C18.4772 34 14 29.5228 14 24C14 18.4772 18.4772 14 24 14C29.5228 14 34 18.4772 34 24Z" stroke="#D92D20" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          <div className="w-full text-center px-6 space-y-2.5">
            <h2 className=" m-0 text-lg font-semibold">
              Meeting Cancelled
            </h2>
            <p className="m-0 text-sm">
              We have cancelled your meeting as per your request.
            </p>
          </div>
          <div className="bg-white p-3 py-4 rounded-2xl space-y-6 w-full">
            <h4 className="text-gray-900 text-lg font-semibold leading-7">Meeting Details</h4>
            <div className="grid grid-cols-2 gap-x-6">
              <div className="space-y-6">
                <div className="space-y-[10px]">
                  <p className="text-slate-600 text-sm font-semibold leading-tight m-0">What</p>
                  <p className="text-slate-600 text-sm font-normal leading-tight capitalize m-0">{data?.bookingTimeSlot} Meeting</p>
                </div>
                <div className="space-y-[10px]">
                  <p className="text-slate-600 text-sm font-semibold leading-tight m-0">When</p>
                  <p className="text-slate-600 text-sm font-normal leading-tight m-0">
                    {moment(data?.bookingData).format('DD MMM YYYY')}
                    {' - '}
                    {convertTo12Hour(data?.bookingTime)} ({data?.bookingTimeZone})
                  </p>
                </div>
              </div>
              <div className="space-y-6">
                <div className="space-y-[10px]">
                  <p className="text-slate-600 text-sm font-semibold leading-tight m-0">Cancelled By</p>
                  <p className="text-slate-600 text-sm font-normal leading-tight flex items-center gap-2.5 m-0">
                    {data?.user?.firstName} {data?.user?.lastName}
                  </p>
                  <p className="text-slate-600 text-sm font-normal leading-tight">{data?.cancelledBy}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </DialogContent>
    </Dialog>
  );
}

export default BookingCancelledStatusModal;
