/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable import/no-unresolved */
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'sonner';
import getMeetingById from '../../../actions/getMeetingById';
import AvatarGroup from '../../../components/ui/avatar-group';
import { Button } from '../../../components/ui/button';
import Config from '../../../config';
import { convertTo24Hour, getGravatarUrl } from '../../../lib/utils';
import ViewMeetingDetailsModal from './view-meeting-details-modal';

export function getHourMinuteDifference(dateString1, dateString2) {
  const date1 = new Date(dateString1);
  const date2 = new Date(dateString2);
  const diffMilliseconds = Math.abs(date2 - date1);
  const days = Math.floor(diffMilliseconds / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diffMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((diffMilliseconds % (1000 * 60 * 60)) / (1000 * 60));

  if (days > 0) {
    return `${days}d`;
  }
  if (hours > 0) {
    return `${hours}h`;
  }
  return `${minutes}m`;
}

function MeetingCardItem({ type, data }) {
  const [showDetails, setShowDetails] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [meetingData, setMeetingData] = useState(null);

  const secondUser = typeof data?.users?.[1] !== 'string' ? data?.users?.[1] : null;
  const userEmail = (secondUser ? secondUser?.email : data?.createdBy?.email) || data?.createdBy?.email;
  const userName = secondUser
    ? `${secondUser?.firstName} ${secondUser?.lastName}`
    : `${data?.createdBy?.firstName} ${data?.createdBy?.lastName}`;

  const gravatarUrl = getGravatarUrl(userName, userEmail);

  useEffect(() => {
    if (data?._id && type === 'upcoming') {
      const getData = async () => {
        const { data: result } = await getMeetingById(data?._id);
        setMeetingData(result.data);
        if (result?.data?.booking?.formSubmission) {
          setIsFormSubmitted(true);
        }
      };

      getData();
    }
  }, [data, type]);

  const userAvatars = typeof data?.users[0] !== 'string' ? data?.users?.map((user) => ({
    src: (user.picture ? `${Config.url || ''}/${user.picture.location}` : getGravatarUrl(`${user.firstName} ${user.lastName}`, user.email)),
    fallback: user.firstName.charAt(0) + user.lastName.charAt(0),
    alt: '',
  })) : data?.users?.map(() => ({
    src: gravatarUrl,
    fallback: userName,
    alt: '',
  }));

  return (
    <div className="flex py-4 px-6 items-center justify-between gap-2.5 rounded-xl border border-gray-200 bg-white">
      <div className="flex items-center gap-3">
        <AvatarGroup avatars={userAvatars} max={typeof data?.users[0] !== 'string' ? 2 : 1} />
        <div className="flex flex-col gap-2">
          <h4 className="text-gray-900 text-sm font-semibold leading-tight m-0">
            {`${data?.createdBy?.firstName || 'Unknown'} ${data?.createdBy?.lastName || ''}`}
          </h4>
          <div className="flex gap-2 text-sm text-slate-600">
            <div>
              {type === 'upcoming' ? `${convertTo24Hour(data?.booking?.bookingTime)} - ` : ''}

              {moment(data?.booking?.bookingDate).format('DD/MM/YYYY')}

              {type === 'upcoming' ? (
                ''
              ) : (
                <>
                  {' - '}
                  {getHourMinuteDifference(new Date(), data?.lastLeave || 0)} ago
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center">
        {type !== 'upcoming' &&
          (data?.status === 'brief_generated' ? (
            <Link to={`/briefs/${data?._id}`} className="text-sm font-semibold leading-tight">
              <Button className="px-6 py-2">Check Brief</Button>
            </Link>
          ) : data?.status === 'generating_brief' ? (
            <Button
              onClick={() => {
                toast.info('Brief generation in progress ...', {
                  position: 'top-center',
                  description: 'We are generating your brief. Please wait for a while.',
                });
              }}
              className="px-6 py-2 animate-pulse cursor-wait"
            >
              Generating Brief ...
            </Button>
          ) : data?.transcriptions ? (
            <Link
              to={`/transcript/${data?._id}`}
              className="px-3 py-2 bg-white border border-gray-300 text-slate-700 text-sm font-semibold leading-tight rounded"
            >
              Check Transcript
            </Link>
          ) : data?.status === 'in_meeting' ? (
            <a
              href={`${Config.meetUrl}${data?.meetingCode}`}
              className="px-3 py-2 bg-white border border-gray-300 text-slate-700 text-sm font-semibold leading-tight rounded flex items-center hover:text-slate-900 gap-2"
            >
              Join
              {data?.peers?.length > 0 && (
                <span className="relative flex h-2.5 w-2.5">
                  <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75" />
                  <span className="relative inline-flex rounded-full h-2.5 w-2.5 bg-green-500" />
                </span>
              )}
            </a>
          ) : data?.status === 'pending' ? (
            <a
              href={`${Config.meetUrl}${data?.meetingCode}`}
              className="px-3 py-2 bg-white border border-gray-300 text-slate-700 text-sm font-semibold leading-tight rounded flex items-center hover:text-slate-900 gap-2"
            >
              Join
            </a>
          ) : null)}

        {type === 'upcoming' && (
          <div className="flex items-center gap-[40px]">
            <div className="flex gap-3 items-center">
              <span title="Meeting Scheduled" className="cursor-pointer">
                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="calendar-check-01">
                    <path
                      id="Icon"
                      d="M17.8008 9.01896H2.80078M13.6341 2.35229V5.68563M6.96745 2.35229V5.68563M7.80078 14.019L9.46745 15.6856L13.2174 11.9356M6.80078 19.019H13.8008C15.2009 19.019 15.901 19.019 16.4358 18.7465C16.9062 18.5068 17.2886 18.1243 17.5283 17.6539C17.8008 17.1192 17.8008 16.4191 17.8008 15.019V8.01896C17.8008 6.61883 17.8008 5.91876 17.5283 5.38399C17.2886 4.91358 16.9062 4.53113 16.4358 4.29145C15.901 4.01896 15.2009 4.01896 13.8008 4.01896H6.80078C5.40065 4.01896 4.70058 4.01896 4.1658 4.29145C3.6954 4.53113 3.31295 4.91358 3.07326 5.38399C2.80078 5.91876 2.80078 6.61883 2.80078 8.01896V15.019C2.80078 16.4191 2.80078 17.1192 3.07326 17.6539C3.31295 18.1243 3.6954 18.5068 4.1658 18.7465C4.70058 19.019 5.40065 19.019 6.80078 19.019Z"
                      stroke="#454545"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </svg>
              </span>
              {isFormSubmitted && (
                <span title="Form Submitted" className="cursor-pointer">
                  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="file-check-02">
                      <path
                        id="Icon"
                        d="M16.9676 11.1023V6.3523C16.9676 4.95216 16.9676 4.2521 16.6951 3.71732C16.4554 3.24691 16.073 2.86446 15.6026 2.62478C15.0678 2.35229 14.3677 2.35229 12.9676 2.35229H7.63428C6.23415 2.35229 5.53408 2.35229 4.9993 2.62478C4.5289 2.86446 4.14644 3.24691 3.90676 3.71732C3.63428 4.2521 3.63428 4.95216 3.63428 6.3523V15.019C3.63428 16.4191 3.63428 17.1192 3.90676 17.6539C4.14644 18.1243 4.5289 18.5068 4.9993 18.7465C5.53408 19.019 6.23415 19.019 7.63428 19.019H10.3009M11.9676 9.8523H6.96761M8.63428 13.1856H6.96761M13.6343 6.51896H6.96761M12.3843 16.519L14.0509 18.1856L17.8009 14.4356"
                        stroke="#454545"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                  </svg>
                </span>
              )}
              {data.peers.length > 0 && (
                <span title="Meeting is Live" className="cursor-pointer text-[#173300] animate-pulse">
                  <svg width="21" height="21" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="video-recorder">
                      <g id="Icon">
                        <path d="M22 9.66477C22 9.05895 22 8.75605 21.8802 8.61578C21.7763 8.49408 21.6203 8.42949 21.4608 8.44205C21.2769 8.45652 21.0627 8.67071 20.6343 9.09908L17 12.7334L20.6343 16.3677C21.0627 16.7961 21.2769 17.0103 21.4608 17.0248C21.6203 17.0373 21.7763 16.9727 21.8802 16.851C22 16.7108 22 16.4078 22 15.802V9.66477Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M2 10.5334C2 8.85324 2 8.01316 2.32698 7.37143C2.6146 6.80694 3.07354 6.348 3.63803 6.06038C4.27976 5.7334 5.11984 5.7334 6.8 5.7334H12.2C13.8802 5.7334 14.7202 5.7334 15.362 6.06038C15.9265 6.348 16.3854 6.80694 16.673 7.37143C17 8.01316 17 8.85324 17 10.5334V14.9334C17 16.6136 17 17.4536 16.673 18.0954C16.3854 18.6599 15.9265 19.1188 15.362 19.4064C14.7202 19.7334 13.8802 19.7334 12.2 19.7334H6.8C5.11984 19.7334 4.27976 19.7334 3.63803 19.4064C3.07354 19.1188 2.6146 18.6599 2.32698 18.0954C2 17.4536 2 16.6136 2 14.9334V10.5334Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      </g>
                    </g>
                  </svg>
                </span>
              )}
            </div>
            <Button onClick={() => setShowDetails(true)} className="px-6 py-2">
              View Details
            </Button>
          </div>
        )}

        {data?._id && (
          <ViewMeetingDetailsModal
            open={showDetails}
            setOpen={setShowDetails}
            meetingData={meetingData}
            meetingId={data?.meetingCode}
          />
        )}
      </div>
    </div>
  );
}

export default MeetingCardItem;
