/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGlobal } from 'reactn';
import setAuthToken from '../../../actions/setAuthToken';
import Config from '../../../config';
import MeetingStats from '../components/meeting-stats';
import ProjectSpaceIntro from '../components/project-space-intro';
import UpcomingMeetings from '../components/upcoming-meetings';
import WelcomeHeading from '../components/welcome-heading';
import Layout from '../layout';

function DashboardIndex() {
  const [user, setUser] = useGlobal('user');
  const [meetingStats, setMeetingStats] = useState(null);

  const setToken = useGlobal('token')[1];
  const navigate = useNavigate();

  const handleLogout = () => {
    setToken(null);
    setUser(null);
    setAuthToken('');
    // localStorage.setItem('token');
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    document.cookie = 'jwt_token=; path=/; domain=.saybriefly.com; secure; SameSite=None;';
  };

  useEffect(() => {
    if (user?.isGuest && !location.pathname.includes('/meeting')) {
      handleLogout();
      navigate('/');
    }
  }, [user]);

  useEffect(() => {
    axios
      .get(`${Config.url || ''}/api/v1/user/dashboard-meeting-stats`)
      .then((data) => {
        setMeetingStats(data?.data?.stats);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Layout>
      <div className="space-y-6 h-full flex flex-col">
        <div className="grid xl:grid-cols-2 grid-cols-1 gap-6">
          <div className="flex flex-col gap-8">
            <WelcomeHeading />
            <div className="space-y-6">
              {/* <MeetingHistory /> */}
              <MeetingStats data={meetingStats} />
              <ProjectSpaceIntro />
            </div>
          </div>
          <UpcomingMeetings />
        </div>
      </div>
    </Layout>
  );
}

export default DashboardIndex;
