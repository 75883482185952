import { Card, CardContent } from '../../../components/ui/card';
import { Progress } from '../../../components/ui/progress';

function UsageProgress({ value = 0, max = 100, unit = 'm' }) {
  // Calculate percentage for progress bar
  const percentage = (value / max) * 100;
  return (
    <Card className="w-full border-none">
      <CardContent className="pt-6 p-4">
        <div className="space-y-6">
          <div className="text-[#101828] text-base font-semibold font-inter leading-normal">Total usage</div>

          <div className="relative pt-6">
            {' '}
            {/* Added top padding for floating label */}
            <div
              className="absolute top-4 transform -translate-y-full transition-all duration-300"
              style={{
                left: `${Math.min(Math.max((percentage > 100 ? 100 : percentage), 0), 100)}%`,
                transform: 'translateX(-50%) translateY(-100%)',
              }}
            >
              <div className="px-2 py-[5.33px] bg-white rounded-md border border-[#eaecf0] text-[#344054] text-sm font-semibold font-inter leading-3">
                {value}
                {unit}
              </div>
            </div>

            <Progress
              value={percentage > 100 ? 100 : percentage}
              className="h-3 bg-slate-100"
            />
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

export default UsageProgress;
