/* eslint-disable import/no-unresolved */
import { useEffect, useState } from 'react';
import { useGlobal } from 'reactn';
import { toast } from 'sonner';
import getWorkspaceMembers from '../../../../actions/getWorkspaceMembers';
import removeWorkspaceMember from '../../../../actions/removeWorkspaceMember';
import { getGravatarUrl } from '../../../../lib/utils';
import AddTeamMemberButton from '../../components/add-team-button';

function Team() {
  const user = useGlobal('user')[0];
  const workspaceId = user?.workspace?._id;
  const [teamMembers, setTeamMembers] = useState();
  const [refresh, setRefresh] = useState(false);

  const getWorkspaceMembersData = async () => {
    try {
      const { data: res } = await getWorkspaceMembers(workspaceId);
      setTeamMembers(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getWorkspaceMembersData();
  }, [refresh]);

  const deleteMember = async (id) => {
    try {
      const concent = window.confirm('Are you sure you want to delete this member?');
      if (concent) {
        const { data: res } = await removeWorkspaceMember(id);
        console.log(res);
        if (res.success) {
          toast.success('Member Deleted Successfully!', {
            position: 'top-center',
          });
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setRefresh(!refresh);
    }
  };

  return (
    <div className={`space-y-8  ${user?.workspace?.createdBy !== user?._id ? 'opacity-60 pointer-events-none' : ''}`}>
      <div className="flex items-center justify-between">
        <div>
          <h4 className="text-[#101828] text-lg font-semibold font-inter leading-7">Teams</h4>
          <p className="text-[#475467] text-sm font-normal font-inter leading-tight">
            You&apos;re on the following teams. You can create a new team here.
          </p>
        </div>
        <AddTeamMemberButton setRefresh={setRefresh} />
      </div>
      <hr />
      <div>
        <h5 className="text-[#344054] text-sm font-semibold leading-tight mb-1">Your team</h5>
        <p className="text-[#475467] text-sm font-normal leading-tight">
          Manage your existing team and change roles/permissions.
        </p>
      </div>
      <table className="w-full rounded-xl overflow-hidden">
        <thead className="bg-[#eaecf0] border-b border-[#eaecf0]">
          <tr>
            <td className="text-[#475467] text-xs font-medium leading-[18px] px-6 py-3 w-[50%]">Title</td>
            <td className="text-[#475467] text-xs font-medium leading-[18px] px-6 py-3">Email</td>
            <td className="text-[#475467] text-xs font-medium leading-[18px] px-6 py-3">Role</td>
            <td className="text-[#475467] text-xs font-medium leading-[18px] px-6 py-3">Status</td>
            <td className="text-[#475467] text-xs font-medium leading-[18px] px-6 py-3 text-right" />
          </tr>
        </thead>
        <tbody>
          {teamMembers?.map((member) => (
            <tr key={member._id} className="border-b border-[#eaecf0]">
              <td className="py-4 px-6">
                <div className="flex items-center gap-3">
                  <img
                    className="w-[40px] h-[40px] rounded-full object-cover object-center"
                    src={getGravatarUrl(`${member.user.firstName} ${member.user.lastName}`, member.user.email)}
                    alt=""
                  />
                  <div>
                    <h4 className="text-[#101828] text-sm font-normal leading-tight mb-1">
                      {member.user.firstName}
                      {member.user.lastName}
                    </h4>
                  </div>
                </div>
              </td>
              <td className="py-4 px-6">
                <a
                  href={`mailto:${member.user.email}`}
                  target="_blank"
                  className="text-[#475467] text-sm font-normal font-inter leading-tight"
                  rel="noreferrer"
                >
                  {member.user.email}
                </a>
              </td>
              <td className="py-4 px-6">{member.role}</td>
              <td className="py-4 px-6">{member.status}</td>
              {user?.workspace?.createdBy !== member.user?._id && (
                <td className="flex justify-end py-4 px-6">
                  <div className="px-6 py-4 justify-end items-center gap-3 inline-flex">
                    <button
                      onClick={() => deleteMember(member._id)}
                      className="text-[#475467] text-sm font-semibold leading-tight"
                    >
                      Delete
                    </button>
                  </div>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Team;
