/* eslint-disable indent */
/* eslint-disable import/prefer-default-export */
export const EMOJIS = [
    {
        type: 'smile',
        src: 'https://fonts.gstatic.com/s/e/notoemoji/latest/1f600/lottie.json',
    },
    {
        type: 'partying_face',
        src: 'https://fonts.gstatic.com/s/e/notoemoji/latest/1f973/lottie.json',
    },
    {
        type: 'heart_eyes',
        src: 'https://fonts.gstatic.com/s/e/notoemoji/latest/1f60d/lottie.json',
    },
    {
        type: 'party_popper',
        src: 'https://fonts.gstatic.com/s/e/notoemoji/latest/1f389/lottie.json',
    },
    {
        type: 'red_heart',
        src: 'https://fonts.gstatic.com/s/e/notoemoji/latest/2764_fe0f/lottie.json',
    },
    {
        type: 'clap',
        src: 'https://fonts.gstatic.com/s/e/notoemoji/latest/1f44f/lottie.json',
    },
];
