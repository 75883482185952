import { useNavigate } from 'react-router-dom';

function BackButton() {
  const navigate = useNavigate();
  return (
    <button onClick={() => navigate(-1)} className="px-3 py-2 bg-white rounded-lg shadow border-2 border-[#878888] justify-center items-center gap-1 flex text-[#323333] text-sm font-semibold leading-tight">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="arrow-circle-left" clipPath="url(#clip0_11648_9531)">
          <path id="Icon" d="M9.99984 6.66675L6.6665 10.0001M6.6665 10.0001L9.99984 13.3334M6.6665 10.0001H13.3332M18.3332 10.0001C18.3332 14.6025 14.6022 18.3334 9.99984 18.3334C5.39746 18.3334 1.6665 14.6025 1.6665 10.0001C1.6665 5.39771 5.39746 1.66675 9.99984 1.66675C14.6022 1.66675 18.3332 5.39771 18.3332 10.0001Z" stroke="currentColor" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
        </g>
        <defs>
          <clipPath id="clip0_11648_9531">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
      Back
    </button>
  );
}

export default BackButton;
