import Conversation from '../../../../../features/Conversation';

/* eslint-disable no-unused-vars */
function BriefTabContents({
  tabs, activeTab, transcript, meetingDetails, highlight, summary,
}) {
  return (
    <div className="px-4 relative">
      {
        tabs[activeTab].name === 'Summary' && (
          <div>
            {meetingDetails?.summary ? <div dangerouslySetInnerHTML={{ __html: summary }} /> : 'Summary is currently unavailable!'}
          </div>
        )
      }
      {
        tabs[activeTab].name === 'Highlights' && (
          <div>
            {highlight ? <div className="leading-[2rem]" dangerouslySetInnerHTML={{ __html: highlight }} /> : 'Highlight is currently unavailable!'}
          </div>
        )
      }

      {
        tabs[activeTab].name === 'Transcript' && (<div dangerouslySetInnerHTML={{ __html: transcript }} />
        )
      }
      {
        tabs[activeTab].name === 'Chatroom' && (
        <div className="h-[600px]">
          <Conversation id={meetingDetails?.meeting?.room} input={false} />
        </div>
        )
      }
    </div>
  );
}

export default BriefTabContents;
