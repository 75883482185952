import axios from 'axios';
import Config from '../config';

const markAllAsRead = () => {
  return axios({
    method: 'post',
    url: `${Config.url || ''}/api/v1/user/mark_all_notifications_as_read`,
  });
};

export default markAllAsRead;
