/* eslint-disable no-mixed-operators */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/jsx-one-expression-per-line */
import { Link } from 'react-router-dom';
import { useGlobal } from 'reactn';
import { Progress } from '../../../components/ui/progress';
import { PLANS } from '../../../constants/Subscription';

function Package() {
  const [user] = useGlobal('user');
  const workspace = user?.workspace;
  const PLANDATA = PLANS?.find((plan) => plan?.id === workspace?.plan);
  return (
    <div className="bg-white rounded-xl shadow border border-gray-200 flex-col justify-start items-start inline-flex w-full">
      <div className="p-6 flex-col justify-start items-start gap-6 flex w-full">
        <div className="gap-8 flex justify-between w-full">
          <div className="flex-col justify-start items-start gap-1 inline-flex">
            <div className="items-center flex gap-3">
              <div className="text-gray-900 text-lg font-semibold leading-7 capitalize">
                {workspace?.plan?.replace(/_/g, ' ')} plan
              </div>
              <p className="bg-white rounded-full border text-zinc-800 text-sm font-medium leading-tight px-3 py-1 m-0">
                Monthly
              </p>
            </div>
            <div className="text-slate-600 text-sm font-normal leading-tight">
              Our most popular plan for small teams.
            </div>
          </div>
          <div className="flex items-end gap-0.5">
            <h4 className="flex justify-start items-end gap-2.5">
              <div className="text-gray-900 text-4xl font-semibold leading-[44px]">{`$${PLANDATA?.priceNumber}`}</div>
              <span className="text-slate-600 text-base font-medium leading-normal">per month</span>
            </h4>
          </div>
        </div>
        <div className="flex-col justify-start items-start gap-3 flex w-full">
          <div className="text-gray-900 text-sm font-medium leading-tight">
            {workspace?.members?.length} of {`${workspace?.plan === 'pay_as_you_go' ? 'unlimited' : workspace?.seats}`}{' '}
            users
          </div>
          <Progress value={((workspace?.members?.length || 0) / workspace?.seats) * 100} className="h-2" />
        </div>
      </div>
      <hr className="w-full m-0" />
      <div className="p-4 justify-end w-full flex">
        <Link to="/settings/plan" className="flex gap-2">
          <div className="text-zinc-800 text-sm font-semibold leading-tight">Upgrade plan</div>
          <img src="/image/icons/arrow-up-right.svg" alt="" className="h-6 w-6" />
        </Link>
      </div>
    </div>
  );
}

export default Package;
