/* eslint-disable object-curly-newline */
/* eslint-disable no-trailing-spaces */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { Button } from '../../../components/ui/button';
import { Dialog, DialogContent, DialogDescription, DialogTitle, DialogTrigger } from '../../../components/ui/dialog';
import { Input } from '../../../components/ui/input';
import Config from '../../../config';
import { cn } from '../../../lib/utils';

function AddContactButton({ className, setRefetch }) {
  const { addToast } = useToasts();
  const [isOpen, setIsOpen] = useState(false);
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');

  const handleAddContact = (e) => {
    e.preventDefault();

    if (!fullName) {
      addToast('Name is required', { appearance: 'error' });
      return;
    }

    if (!email) {
      addToast('Email is required', { appearance: 'error' });
      return;
    }

    const firstName = fullName.split(' ')[0];
    const lastName = fullName.split(' ')[1];

    axios
      .post(`${Config.url || ''}/api/v1/user/add_contact`, {
        firstName,
        lastName,
        email,
      })
      .then((res) => {
        addToast(res.data.message, { appearance: 'success' });
        setIsOpen(false);
        setFullName('');
        setEmail('');
        if (setRefetch) {
          setRefetch(true);
        }
      })
      .catch((err) => {
        addToast(err?.response?.data?.error || 'Something went wrong', { appearance: 'error' });
      });
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger>
        <Button className={cn('flex items-center gap-2', className)}>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="plus-circle">
              <path
                id="Solid"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.0007 0.833252C4.93804 0.833252 0.833984 4.93731 0.833984 9.99992C0.833984 15.0625 4.93804 19.1666 10.0007 19.1666C15.0633 19.1666 19.1673 15.0625 19.1673 9.99992C19.1673 4.93731 15.0633 0.833252 10.0007 0.833252ZM10.0007 5.83325C10.4609 5.83325 10.834 6.20635 10.834 6.66658V9.16658H13.334C13.7942 9.16658 14.1673 9.53968 14.1673 9.99992C14.1673 10.4602 13.7942 10.8333 13.334 10.8333H10.834V13.3333C10.834 13.7935 10.4609 14.1666 10.0007 14.1666C9.54041 14.1666 9.16732 13.7935 9.16732 13.3333V10.8333H6.66732C6.20708 10.8333 5.83398 10.4602 5.83398 9.99992C5.83398 9.53968 6.20708 9.16658 6.66732 9.16658H9.16732V6.66658C9.16732 6.20635 9.54041 5.83325 10.0007 5.83325Z"
                fill="currentColor"
              />
            </g>
          </svg>
          <span className="text-base font-semibold leading-normal">Add Contact</span>
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogTitle>Add Contact</DialogTitle>
        {/* To Remove Warning */}
        <DialogDescription className="hidden" />
        <form onSubmit={handleAddContact}>
          <div className="space-y-3">
            <div className="space-y-1">
              <label htmlFor="name" className="text-[#344054] text-sm font-medium font-inter leading-tight">
                <span>Full Name</span>
              </label>
              <Input
                id="name"
                name="fullName"
                required
                type="text"
                className="h-12 px-3.5 py-3 bg-white rounded-lg shadow border border-[#d0d5dd]"
                onChange={(e) => setFullName(e.target.value)}
              />
            </div>
            <div className="space-y-1">
              <label htmlFor="email" className="text-[#344054] text-sm font-medium font-inter leading-tight">
                <span>Email</span>
              </label>
              <Input
                id="email"
                name="email"
                required
                type="email"
                className="h-12 px-3.5 py-3 bg-white rounded-lg shadow border border-[#d0d5dd]"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <Button onClick={handleAddContact} type="submit">
              Add Contact
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default AddContactButton;
