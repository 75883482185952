/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable indent */
/* eslint-disable react/jsx-no-useless-fragment */

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGlobal } from 'reactn';
import { Player } from '@lottiefiles/react-lottie-player';
import MeetingPeopleBar from './MeetingPeopleBar';
import MeetingInfo from './MeetingInfo';
import Conversation from '../../Conversation/index';
import Actions from '../../../constants/Actions';
import { EMOJIS } from '../../../constants/Emojis';

/* eslint-disable react/jsx-indent */
export default function MeetingBottomRight({ chatRoomId, chatRoomLoading }) {
  const dispatch = useDispatch();
  const [meetingPeople, setmeetingPeople] = useState(false);
  const [meetingInfo, setMeetingInfo] = useState(false);

  const [streams] = useGlobal('streams');
  const [meeting] = useGlobal('meeting');
  const [reactions] = useGlobal('reactions');
  const consumers = useSelector((state) => state.rtc.consumers);
  const producers = useSelector((state) => state.rtc.producers);
  const peers = useSelector((state) => state.rtc.peers);
  const socketID = useSelector((state) => state.io.id);

  const newMessages = useSelector((state) => state.messages.roomsWithNewMessages) || [];
  const roomOpen = useSelector((state) => state.messages.roomOpen);

  const actualConsumers = consumers.filter((c) => c !== socketID);
  let actualPeers = [];
  actualConsumers.forEach((consumerID) => {
    const actualPeer = {
      ...peers[consumerID],
      video: null,
      audio: null,
      screen: null,
    };
    const peerStreams = streams.filter((s) => s.socketID === consumerID);
    peerStreams.forEach((stream) => {
      actualPeer.streams = [...(actualPeer.streams || []), stream];
      if (stream.isVideo) return (actualPeer.video = stream);
      actualPeer.audio = stream;
    });
    const isScreen = (actualPeer.video || actualPeer.screen)
      && producers.filter((p) => p.producerID === actualPeer.video.producerID && p.isScreen).length > 0;
    actualPeers.push({ ...actualPeer, isScreen });
  });

  actualPeers = actualPeers.filter((peer) => peer.approveJoin === true).sort((a) => (((a.audio !== null) || (a.screen !== null) || (a.video !== null)) ? -1 : 1));

  // Function to get emoji reactions
  const getEmojiReactions = () => {
    let emojis = reactions?.length > 0 ? reactions : [];
    actualPeers.forEach((peer) => {
      // eslint-disable-next-line no-unused-expressions
      peer.reactions?.length > 0 && peer.reactions?.forEach((reaction) => {
        const { timestamp, emojiType } = reaction;
        emojis.push({ timestamp, emojiType, user: peer?.user });
      });
    });

    const now = Date.now();
    emojis = emojis.filter((emoji) => now - emoji.timestamp < 5000);

    return emojis?.length > 0 ? emojis : [];
  };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     getEmojiReactions();
  //   }, 1000);

  //   return () => clearInterval(interval);
  // }, [actualPeers]);

  return (
    <>
      <div className={`${meetingPeople ? 'w-[25%] opacity-100 bg-white/90 backdrop-blur-md' : 'w-[0px] opacity-0'} flex items-start justify-start transition-all duration-150 ease-linear h-full z-[9999]`}>
        <MeetingPeopleBar setmeetingPeople={setmeetingPeople} actualPeers={actualPeers} meeting={meeting} />
      </div>
      <div className={`${meetingInfo ? 'w-[25%] opacity-100 bg-white/90 backdrop-blur-md' : 'w-[0px] opacity-0'} flex items-start justify-start transition-all duration-150 ease-linear h-full z-[9999]`}>
        <MeetingInfo setMeetingInfo={setMeetingInfo} />
      </div>
      <div className={`${roomOpen ? 'w-[450px] opacity-100 bg-white/90 backdrop-blur-md' : 'w-[0px] opacity-0'} flex items-center justify-center transition-all duration-150 ease-linear h-full z-[9999]`}>
        {
          chatRoomId && <Conversation roomOpen={roomOpen} closeChat={() => dispatch({ type: Actions.SET_ROOM_TAB_STATUS, roomOpen: false })} id={chatRoomId} chatRoomLoading={chatRoomLoading} />
        }
      </div>
      <div className="flex gap-2.5 p-2 rounded-xl fixed bottom-5 right-4">
        <div
          onClick={() => setMeetingInfo(!meetingInfo)}
          className="cursor-pointer w-12 h-12 p-3 rounded-xl justify-center items-center flex text-white bg-black"
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M12 17V11" stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round"></path> <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 11 9)" fill="#ffffff"></circle> <path d="M22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C21.5093 4.43821 21.8356 5.80655 21.9449 8" stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round"></path> </g></svg>
        </div>

        <div
          onClick={() => dispatch({ type: Actions.SET_ROOM_TAB_STATUS, roomOpen: !roomOpen })}
          className={`cursor-pointer w-12 h-12 p-3 rounded-xl justify-center items-center flex text-white bg-black ${roomOpen ? '' : ' text-white'} relative`}
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="message-chat-circle">
              <path id="Icon" d="M6.09436 11.2288C6.03221 10.8282 5.99996 10.4179 5.99996 10C5.99996 5.58172 9.60525 2 14.0526 2C18.4999 2 22.1052 5.58172 22.1052 10C22.1052 10.9981 21.9213 11.9535 21.5852 12.8345C21.5154 13.0175 21.4804 13.109 21.4646 13.1804C21.4489 13.2512 21.4428 13.301 21.4411 13.3735C21.4394 13.4466 21.4493 13.5272 21.4692 13.6883L21.8717 16.9585C21.9153 17.3125 21.9371 17.4895 21.8782 17.6182C21.8266 17.731 21.735 17.8205 21.6211 17.8695C21.4911 17.9254 21.3146 17.8995 20.9617 17.8478L17.7765 17.3809C17.6101 17.3565 17.527 17.3443 17.4512 17.3448C17.3763 17.3452 17.3245 17.3507 17.2511 17.3661C17.177 17.3817 17.0823 17.4172 16.893 17.4881C16.0097 17.819 15.0524 18 14.0526 18C13.6344 18 13.2237 17.9683 12.8227 17.9073M7.63158 22C10.5965 22 13 19.5376 13 16.5C13 13.4624 10.5965 11 7.63158 11C4.66668 11 2.26316 13.4624 2.26316 16.5C2.26316 17.1106 2.36028 17.6979 2.53955 18.2467C2.61533 18.4787 2.65322 18.5947 2.66566 18.6739C2.67864 18.7567 2.68091 18.8031 2.67608 18.8867C2.67145 18.9668 2.65141 19.0573 2.61134 19.2383L2 22L4.9948 21.591C5.15827 21.5687 5.24 21.5575 5.31137 21.558C5.38652 21.5585 5.42641 21.5626 5.50011 21.5773C5.5701 21.5912 5.67416 21.6279 5.88227 21.7014C6.43059 21.8949 7.01911 22 7.63158 22Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </g>
          </svg>
          {newMessages.length > 0 && (
            <span className="text-white text-xs font-semibold leading-normal absolute -top-3 -right-2 bg-red-500 px-2 py-1 rounded-full">
              {newMessages.length > 99 ? '99+' : newMessages.length}
            </span>
          )}
        </div>
        <div
          onClick={() => setmeetingPeople(!meetingPeople)}
          className="cursor-pointer w-12 h-12 p-3 rounded-xl justify-center items-center flex text-white bg-black relative"
        >
          <svg width="64px" height="64px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M18 7.16C17.94 7.15 17.87 7.15 17.81 7.16C16.43 7.11 15.33 5.98 15.33 4.58C15.33 3.15 16.48 2 17.91 2C19.34 2 20.49 3.16 20.49 4.58C20.48 5.98 19.38 7.11 18 7.16Z" stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M16.9699 14.44C18.3399 14.67 19.8499 14.43 20.9099 13.72C22.3199 12.78 22.3199 11.24 20.9099 10.3C19.8399 9.59004 18.3099 9.35003 16.9399 9.59003" stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M5.96998 7.16C6.02998 7.15 6.09998 7.15 6.15998 7.16C7.53998 7.11 8.63998 5.98 8.63998 4.58C8.63998 3.15 7.48998 2 6.05998 2C4.62998 2 3.47998 3.16 3.47998 4.58C3.48998 5.98 4.58998 7.11 5.96998 7.16Z" stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M6.99994 14.44C5.62994 14.67 4.11994 14.43 3.05994 13.72C1.64994 12.78 1.64994 11.24 3.05994 10.3C4.12994 9.59004 5.65994 9.35003 7.02994 9.59003" stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M12 14.63C11.94 14.62 11.87 14.62 11.81 14.63C10.43 14.58 9.32996 13.45 9.32996 12.05C9.32996 10.62 10.48 9.46997 11.91 9.46997C13.34 9.46997 14.49 10.63 14.49 12.05C14.48 13.45 13.38 14.59 12 14.63Z" stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M9.08997 17.78C7.67997 18.72 7.67997 20.26 9.08997 21.2C10.69 22.27 13.31 22.27 14.91 21.2C16.32 20.26 16.32 18.72 14.91 17.78C13.32 16.72 10.69 16.72 9.08997 17.78Z" stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg>
          {
            actualPeers.length > 0 && (
              <span className="text-white text-xs font-semibold leading-normal absolute -top-3 -right-2 bg-red-500 px-2 py-1 rounded-full">
                {actualPeers.length > 99 ? '99+' : actualPeers.length}
              </span>
            )
          }
        </div>
      </div>

      {/* // Emoji Flow */}
      <div className="fixed bottom-5 left-10 transform -translate-x-1/2 flex flex-col items-center space-y-2 pointer-events-none">
        {getEmojiReactions().map((emoji, index) => (
          emoji?.timestamp > Date.now() - 10000 && (
            <div
              key={index}
              className="animate-emoji-float text-3xl"
              style={{ animationDelay: `${index * 0.5}s`, marginRight: `-${(Math.random() * 6).toFixed(1)}rem` }}
            >
              <Player
                src={EMOJIS.find((e) => e.type === emoji?.emojiType)?.src}
                loop
                autoplay
                style={{ height: '3rem', width: '3rem' }}
              />
              <div className="bg-black text-white text-xs font-semibold leading-normal px-2 py-1 rounded-full">
                {emoji?.user?.firstName}
              </div>
            </div>
          )
        ))}
      </div>

    </>
  );
}
