import axios from 'axios';
import Config from '../config';

const setCurrentWorkspace = (data) => {
  return axios({
    method: 'post',
    url: `${Config.url || ''}/api/v1/user/workspace/set_current_workspace`,
    data,
  });
};

export default setCurrentWorkspace;
