import { useState } from 'react';
import Sidebar from './components/sidebar';
import Topbar from './components/topbar';

function Layout({ children }) {
  const [showSideBar, setShowSideBar] = useState(false);
  return (
    <div className="flex h-screen">
      <Sidebar showSideBar={showSideBar} />
      <main className="flex-1 flex flex-col bg-[#F9FAFB]">
        <Topbar setShowSideBar={setShowSideBar} showSideBar={showSideBar} />
        <div onClick={() => setShowSideBar(false)} className="flex-1 overflow-y-scroll scroll-none p-8">
          <div className="container h-full">
            {children}
          </div>
        </div>
      </main>
    </div>
  );
}

export default Layout;
