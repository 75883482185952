/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import { REGEXP_ONLY_DIGITS_AND_CHARS } from 'input-otp';
import { useEffect, useState } from 'react';
import { useGlobal } from 'reactn';
import { toast } from 'sonner';
import changePassword from '../../../../actions/changePassword';
import changeProfilePicture from '../../../../actions/changeProfilePicture';
import { Avatar, AvatarFallback, AvatarImage } from '../../../../components/ui/avatar';
import { Button } from '../../../../components/ui/button';
import {
  Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle,
} from '../../../../components/ui/dialog';
import { Input } from '../../../../components/ui/input';
import {
  InputOTP, InputOTPGroup,
  InputOTPSlot,
} from '../../../../components/ui/input-otp';
import Config from '../../../../config';

function MyDetails() {
  const [user, setUser] = useGlobal('user');
  const token = useGlobal('token')[0];

  // Profile Picture
  const [profileUrl, setProfileUrl] = useState('');
  const [profilePicture, setProfilePicture] = useState(null);
  const [isSavingProfilePicture, setIsSavingProfilePicture] = useState(false);

  // Profile Progress
  const [progress, setProgress] = useState(0);

  const [isSavingProfile, setIsSavingProfile] = useState(false);

  // Is Verify Modal Open
  const [isVerifyModalOpen, setIsVerifyModalOpen] = useState(false);
  const [isVerifyModalOpening, setIsVerifyModalOpening] = useState(false);
  const [isVerifyLoading, setIsVerifyLoading] = useState(false);

  const [otp, setOTP] = useState('');

  const handleRequestVerify = async () => {
    try {
      setIsVerifyModalOpening(true);
      const { data: res } = await axios({
        method: 'post',
        url: `${Config.url || ''}/api/v1/user/send_email_verification`,
      });
      if (res.success) {
        setIsVerifyModalOpen(true);
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: 'top-center',
      });
      console.log(err);
    } finally {
      setIsVerifyModalOpening(false);
    }
  };

  useEffect(() => {
    try {
      const getData = async () => {
        const { data: res } = await axios({
          method: 'get',
          url: `${Config.url || ''}/api/v1/user/progress`,
        });
        setProgress(res.data.profileProgress);
      };
      getData();
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleChangePassword = async (e) => {
    e.preventDefault();
    const { password, newPassword, confirmPassword } = e.target;

    if (newPassword.value !== confirmPassword.value) {
      toast.error('Passwords do not match.', {
        position: 'top-center',
        description: 'Please type the same password twice.',
      });
      return;
    }

    try {
      const { data: res } = await changePassword(password.value, newPassword.value, token);

      if (res.success) {
        toast.success(res.message, {
          position: 'top-center',
        });
      } else {
        toast.error(res.message, {
          position: 'top-center',
        });
      }
      e.target.reset();
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        position: 'top-center',
      });
    } finally {
    }
  };
  const handleChangeProfilePicture = async (e) => {
    e.preventDefault();
    const { files } = e.target;
    if (files.length > 0) {
      setProfilePicture(files[0]);

      // Make Image url to show Profile Picture
      const reader = new FileReader();
      reader.onload = (e) => {
        setProfileUrl(e.target.result);
      };
      reader.readAsDataURL(files[0]);
    }
  };
  const saveProfilePicture = async () => {
    try {
      setIsSavingProfilePicture(true);
      const formData = new FormData();
      formData.append('image', profilePicture);
      formData.append('crop', 'square');

      const { data } = await changeProfilePicture(formData);
      if (data.success) {
        toast.success('Profile picture updated successfully.', {
          position: 'top-center',
        });
        const { data: res } = await axios({
          method: 'get',
          url: `${Config.url || ''}/api/v1/user/me`,
        });
        setUser(res.data);
        setProfileUrl('');
        setProfilePicture(null);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsSavingProfilePicture(false);
    }
  };
  const updateProfileInfo = async (e) => {
    try {
      setIsSavingProfile(true);
      e.preventDefault();
      const {
        firstName, lastName, email, username, phone, location, company, tagLine,
      } = e.target;
      const data = {
        firstName: firstName.value,
        lastName: lastName.value,
        email: email.value,
        username: username.value,
        phone: phone.value,
        location: location.value,
        company: company.value,
        tagLine: tagLine.value,
      };
      const { data: res } = await axios({
        method: 'post',
        url: `${Config.url || ''}/api/v1/user/update_info`,
        data,
      });
      // console.log(res);
      if (res.success) {
        toast.success('Profile updated successfully.', {
          position: 'top-center',
        });
        const { data: user } = await axios({
          method: 'get',
          url: `${Config.url || ''}/api/v1/user/me`,
        });
        setUser(user.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsSavingProfile(false);
    }
  };

  const handleVerifyOTP = async () => {
    if (!otp) {
      toast.error('Please Enter your OTP', {
        position: 'top-center',
      });
      return;
    }
    try {
      setIsVerifyLoading(true);
      const { data: res } = await axios({
        method: 'post',
        url: `${Config.url || ''}/api/v1/user/verify_email`,
        data: {
          emailCode: otp,
        },
      });
      if (res.success) {
        toast.success(res.message, {
          position: 'top-center',
        });
        const { data: user } = await axios({
          method: 'get',
          url: `${Config.url || ''}/api/v1/user/me`,
        });
        setUser(user.data);
      }
    } catch (err) {
      console.log(err);
      toast.error('Something went wrong!', {
        description: 'Please try again.',
        position: 'top-center',
      });
    } finally {
      setIsVerifyLoading(false);
      setIsVerifyModalOpen(false);
    }
  };
  return (
    <>
      <div className="space-y-8 pb-6">
        <form onSubmit={updateProfileInfo} className="space-y-3">
          <h2 className="text-gray-900 text-lg font-semibold leading-7 mb-2">
            Personal info
            {' '}
            <small>
              (
              {progress}
              % profile completed)
            </small>
          </h2>
          <p className="text-slate-600 text-sm font-normal leading-tight m-0">Update your photo and personal details here.</p>
          <hr />
          <div className="flex flex-col gap-4 items-start">
            <div className="relative group overflow-hidden">
              <Avatar className="size-20 border">
                <AvatarImage src={profileUrl || (user?.picture ? `${Config?.url}${user?.picture.location.slice(1)}` : '')} alt={user?.username} className="object-cover object-center" />
                <AvatarFallback>
                  {user?.firstName[0]}
                  {user?.lastName[0]}
                </AvatarFallback>
              </Avatar>
              <label htmlFor="profile-picture" className="absolute w-full h-full top-0 left-0 flex items-center justify-center bg-gray-500/50 backdrop-blur-sm rounded-full text-white cursor-pointer opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 relative bottom-[-100px] group-hover:bottom-0 transition-all duration-300 ease-in-out">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 16.5V9.75m0 0 3 3m-3-3-3 3M6.75 19.5a4.5 4.5 0 0 1-1.41-8.775 5.25 5.25 0 0 1 10.233-2.33 3 3 0 0 1 3.758 3.848A3.752 3.752 0 0 1 18 19.5H6.75Z" />
                </svg>
                <input
                  type="file"
                  id="profile-picture"
                  name="profilePicture"
                  className="hidden"
                  accept="image/*"
                  onChange={handleChangeProfilePicture}
                />
              </label>
            </div>
            {
              profilePicture && (
                <Button type="button" onClick={saveProfilePicture}>
                  Save
                  {' '}
                  {isSavingProfilePicture && <span className="size-3 border-2 border-white border-t-transparent ml-1 animate-spin rounded-full block" />}
                </Button>
              )
            }
          </div>
          <hr />
          <div className="grid grid-cols-4">
            <div>
              <label htmlFor="name" className="text-slate-700 text-sm font-semibold leading-tight">
                Name
              </label>
            </div>
            <div className="lg:col-span-2 col-span-3">
              <div className="grid grid-cols-2 gap-4">
                <Input id="name" name="firstName" placeholder="First Name" defaultValue={user?.firstName} />
                <Input placeholder="Last Name" name="lastName" defaultValue={user?.lastName} />
              </div>
            </div>
          </div>
          <hr />
          <div className="grid grid-cols-4">
            <div>
              <label htmlFor="email" className="text-slate-700 text-sm font-semibold leading-tight">
                Email address
              </label>
            </div>
            <div className="lg:col-span-2 col-span-3">
              <Input placeholder="Email" type="email" id="email" className="w-full" value={user?.email} name="email" readOnly />
              {
                !user?.emailVerifiedAt && (
                <small onClick={handleRequestVerify} className="text-xs ml-1 flex items-center gap-1 mt-1">
                  Your email is not verified yet.
                  <span className="underline cursor-pointer">Click here to verify</span>
                  {
                  isVerifyModalOpening && <span className="size-2.5 border border-black border-t-transparent ml-1 animate-spin rounded-full block" />
                }
                </small>
                )
              }
            </div>
          </div>
          <hr />
          <div className="grid grid-cols-4">
            <div>
              <label htmlFor="username" className="text-slate-700 text-sm font-semibold leading-tight">
                Username
              </label>
            </div>
            <div className="lg:col-span-2 col-span-3">
              <Input placeholder="Username" type="text" id="username" className="w-full" defaultValue={user?.username} name="username" />
            </div>
          </div>
          <hr />
          <div className="grid grid-cols-4">
            <div>
              <label htmlFor="phone" className="text-slate-700 text-sm font-semibold leading-tight">
                Phone
              </label>
            </div>
            <div className="lg:col-span-2 col-span-3">
              <Input
                placeholder="Phone"
                type="text"
                id="phone"
                name="phone"
                className="w-full"
                defaultValue={user?.phone}
                onChange={(e) => {
                  const { value } = e.target;
                  if (!(/^\+?\d*$/.test(value))) {
                    return null;
                  }
                }}
              />
            </div>
          </div>
          <hr />
          <div className="grid grid-cols-4">
            <div>
              <label htmlFor="location" className="text-slate-700 text-sm font-semibold leading-tight">
                Location
              </label>
            </div>
            <div className="lg:col-span-2 col-span-3">
              <Input
                placeholder="Location"
                type="text"
                id="location"
                name="location"
                className="w-full"
                defaultValue={user?.location}
              />
            </div>
          </div>
          <hr />
          <div className="grid grid-cols-4">
            <div>
              <label htmlFor="company" className="text-slate-700 text-sm font-semibold leading-tight">
                Company
              </label>
            </div>
            <div className="lg:col-span-2 col-span-3">
              <Input
                placeholder="Company"
                type="text"
                id="company"
                name="company"
                className="w-full"
                defaultValue={user?.company}
              />
            </div>
          </div>
          <hr />
          <div className="grid grid-cols-4">
            <div>
              <label htmlFor="tagLine" className="text-slate-700 text-sm font-semibold leading-tight">
                Tag Line
              </label>
            </div>
            <div className="lg:col-span-2 col-span-3">
              <Input
                placeholder="Tag Line"
                type="text"
                id="tagLine"
                name="tagLine"
                className="w-full"
                defaultValue={user?.tagLine}
              />
            </div>
          </div>
          <hr />
          <div className="flex gap-4">
            <Button type="submit">
              Save
              {' '}
              {
                isSavingProfile && <span className="flex size-3 border-2 border-white border-t-transparent ml-1 animate-spin rounded-full" />
              }
            </Button>
          </div>
        </form>
        <form onSubmit={handleChangePassword} className="space-y-3">
          <h2 className="text-gray-900 text-lg font-semibold leading-7 mb-2">Password</h2>
          <p className="text-slate-600 text-sm font-normal leading-tight m-0">Please enter your current password to change your password.</p>
          <hr />
          <div className="grid grid-cols-4">
            <div>
              <label htmlFor="current-password" className="text-slate-700 text-sm font-semibold leading-tight">
                Current password
              </label>
            </div>
            <div className="lg:col-span-2 col-span-3">
              <Input id="current-password" name="password" placeholder="Current password" type="password" required />
            </div>
          </div>
          <hr />
          <div className="grid grid-cols-4">
            <div>
              <label htmlFor="new-password" className="text-slate-700 text-sm font-semibold leading-tight">
                New password
              </label>
            </div>
            <div className="lg:col-span-2 col-span-3">
              <Input id="new-password" name="newPassword" placeholder="New password" type="password" className="w-full" required />
            </div>
          </div>
          <hr />
          <div className="grid grid-cols-4">
            <div>
              <label htmlFor="confirm-new-password" className="text-slate-700 text-sm font-semibold leading-tight">
                Confirm new password
              </label>
            </div>
            <div className="lg:col-span-2 col-span-3">
              <Input id="confirm-new-password" name="confirmPassword" placeholder="Confirm new password" type="password" required className="w-full" />
            </div>
          </div>
          <hr />
          <div className="flex gap-4">
            {/* <Button className="bg-white hover:bg-gray-50 text-slate-700 border">
            Cancel
          </Button> */}
            <Button type="submit">
              Save
            </Button>
          </div>
        </form>
      </div>

      <Dialog open={isVerifyModalOpen} onOpenChange={setIsVerifyModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              Verify your email address.
            </DialogTitle>
            <DialogDescription>
              Please check your email and enter the OTP to verify your email address.
            </DialogDescription>
          </DialogHeader>
          <div className="space-y-3">
            <InputOTP
              value={otp}
              onChange={(value) => setOTP(value)}
              maxLength={6}
              pattern={REGEXP_ONLY_DIGITS_AND_CHARS}
            >
              <InputOTPGroup>
                <InputOTPSlot index={0} />
                <InputOTPSlot index={1} />
                <InputOTPSlot index={2} />
              </InputOTPGroup>
              {/* <InputOTPSeparator /> */}
              <InputOTPGroup>
                <InputOTPSlot index={3} />
                <InputOTPSlot index={4} />
                <InputOTPSlot index={5} />
              </InputOTPGroup>
            </InputOTP>
            <Button onClick={handleVerifyOTP} className="flex items-center gap-1">
              Verify OTP
              {
                isVerifyLoading && <span className="size-2.5 border border-white border-t-transparent ml-1 animate-spin rounded-full block" />
              }
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default MyDetails;
