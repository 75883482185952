import axios from 'axios';
import Config from '../config';

const getResources = (page, limit) => {
  return axios({
    method: 'get',
    url: `${Config.url || ''}/api/v1/user/resources${page ? `?page=${page}` : ''}${limit ? `&limit=${limit}` : ''}`,
    data: {},
  });
};

export default getResources;
