import axios from 'axios';
import Config from '../config';

const getBriefs = (page, limit, status) => {
  return axios({
    method: 'get',
    url: `${Config.url || ''}/api/v1/user/briefs${page ? `?page=${page}` : ''}${limit ? `&limit=${limit}` : ''}${status ? `&status=${status}` : ''}`,
    data: {},
  });
};

export default getBriefs;
