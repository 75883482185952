import axios from 'axios';
import Config from '../config';

const getWorkspaceMembers = (workspaceId) => {
  return axios({
    method: 'get',
    url: `${Config.url || ''}/api/v1/user/workspace/workspace_members/${workspaceId}`,
  });
};

export default getWorkspaceMembers;
