/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable indent */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/function-component-definition */

import axios from 'axios';
import { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { Cross } from 'lucide-react';
import { useGlobal } from 'reactn';
// eslint-disable-next-line import/no-unresolved
import { initializePaddle } from '@paddle/paddle-js';
import { useLocation } from 'react-router-dom';
import { toast } from 'sonner';
import { Button } from '../../../../components/ui/button';
import config from '../../../../config';
import { PLANS } from '../../../../constants/Subscription';

const PricingPlan = () => {
  // Create a local state to store Paddle instance
  const [paddle, setPaddle] = useState();
  const [user] = useGlobal('user');
  const token = useGlobal('token')[0];
  const [selectedSeats, setSelectedSeats] = useState(1);
  const [changePlanWithSeats, setChangePlanWithSeats] = useState(1);
  const location = useLocation();
  const trxId = location?.search?.split('_ptxn=')?.[1];
  const [loading, setLoading] = useState(false);
  // const navigate = useNavigate();

  // Handle checkout with Paddle
  const handleCheckout = (priceId, quantity) => {
    if (!user?.workspace?._id || !user?._id) {
      window.alert('Reload the page and try again!');
      window.location.reload();
      return;
    }
    setLoading(true);
    paddle?.Checkout.open({
      items: [{ priceId, quantity }],
      customData: {
        workspace_id: user?.workspace?._id,
        user_id: user?._id,
      },
      customer: {
        email: user?.email,
        id: user?.workspace?.paddleCustomerId ? user?.workspace?.paddleCustomerId : null,
      },

      settings: {
        successUrl: trxId
          ? `${window.location.origin}/settings/plan/?message=update_billing_success`
          : `${window.location.origin}/settings/plan/?message=success`,
      },
    });
  };

  const handleSeatsChange = (priceId) => {
    setLoading(true);
    axios
      .post(
        `${config.url || ''}/api/v1/user/update-seats-subscription`,
        {
          seats: selectedSeats,
          priceId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => {
        // if (res.data.success) {
        //   handleCheckout(priceId, quantity);
        // }
        toast.success(res.data.message, {
          position: 'top-center',
        });
        window.location.href = `${window.location.origin}/settings/plan/?message=success`;
      })
      .catch(async (err) => {
        console.log(err);
        if (err.response?.data?.error?.type === 'request_error') {
          toast.success('Please update billing details and try again!', {
            position: 'top-center',
          });
          // wait here for 3 seconds
          await new Promise((resolve) => {
            setTimeout(resolve, 3000);
          });
          const trxId = err.response?.data?.error?.trx_id;
          if (trxId) {
            window.location.href = `${window.location.origin}/settings/plan/?_ptxn=${trxId}`;
          }
        } else {
          toast.success(`${err.response?.data?.message}`, {
            position: 'top-center',
          });
          setLoading(false);
        }
      });
  };

  const handleChangePlan = (priceId) => {
    if (user?.workspace?.plan === 'pay_as_you_go') {
      const answer = window.confirm('You will be charged as per usage before changing plan. Are you okay?');
      if (!answer) return;
    }

    setLoading(true);
    axios
      .post(
        `${config.url || ''}/api/v1/user/change-subscription`,
        {
          seats: changePlanWithSeats,
          priceId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => {
        // if (res.data.success) {
        //   handleCheckout(priceId, quantity);
        // }
        toast.success(res.data.message, {
          position: 'top-center',
        });
        window.location.href = `${window.location.origin}/settings/plan/?message=success`;
      })
      .catch(async (err) => {
        console.log(err);
        if (err.response?.data?.error?.type === 'request_error') {
          toast.success('Please update billing details and try again!', {
            position: 'top-center',
          });
          // wait here for 3 seconds
          await new Promise((resolve) => {
            setTimeout(resolve, 3000);
          });
          const trxId = err.response?.data?.error?.trx_id;
          if (trxId) {
            window.location.href = `${window.location.origin}/settings/plan/?_ptxn=${trxId}`;
          }
        } else {
          toast.success(`${err.response?.data?.message}`, {
            position: 'top-center',
          });
          setLoading(false);
        }
      });
  };

  const handlePayAsYouGoPayment = () => {
    setLoading(true);
    axios
      .post(`${config.url || ''}/api/v1/user/pay-as-you-go-payment`, undefined, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // if (res.data.success) {
        //   handleCheckout(priceId, quantity);
        // }
        toast.success(res.data.message, {
          position: 'top-center',
        });
        window.location.href = `${window.location.origin}/settings/plan/?message=success`;
      })
      .catch(async (err) => {
        console.log(err);
        if (err.response?.data?.error?.type === 'request_error') {
          toast.success('Please update billing details and try again!', {
            position: 'top-center',
          });
          // wait here for 3 seconds
          await new Promise((resolve) => {
            setTimeout(resolve, 3000);
          });
          const trxId = err.response?.data?.error?.trx_id;
          if (trxId) {
            window.location.href = `${window.location.origin}/settings/plan/?_ptxn=${trxId}`;
          }
        } else {
          toast.success(`${err.response?.data?.message}`, {
            position: 'top-center',
          });
          setLoading(false);
        }
      });
  };

  const handleSwitchToFreePlan = () => {
    if (user?.workspace?.plan === 'pay_as_you_go') {
      const answer = window.confirm('You will be charged as per usage before changing plan. Are you okay?');
      if (!answer) return;
    }

    setLoading(true);
    axios
      .post(`${config.url || ''}/api/v1/user/switch-to-free-plan`, undefined, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // if (res.data.success) {
        //   handleCheckout(priceId, quantity);
        // }
        toast.success(res.data.message, {
          position: 'top-center',
        });
        window.location.href = `${window.location.origin}/settings/plan/?message=success`;
      })
      .catch(async (err) => {
        console.log(err);
        if (err.response?.data?.message) {
          toast.success(`${err.response?.data?.message}`, {
            position: 'top-center',
          });
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
  };

  // Download and initialize Paddle instance from CDN
  useEffect(() => {
    initializePaddle({ environment: 'sandbox', token: 'test_8bb35b35fe5e76274549501c179' }).then((paddleInstance) => {
      if (paddleInstance) {
        setPaddle(paddleInstance);
      }
    });
  }, []);

  const message = location?.search?.split('?message=')?.[1];
  useEffect(() => {
    if (!user?.workspace?._id || !user?._id) {
      // window.location.reload();
    } else {
      setChangePlanWithSeats(user?.workspace?.seats);
    }
  }, [user]);

  useEffect(() => {
    if (message === 'success' || message === 'update_billing_success') {
      setLoading(true);
      setTimeout(() => {
        window.location.href = `${window.location.origin}/settings/plan`;
      }, 3000);
    }
  }, [message]);

  return (
    <div className="container mx-auto pb-12 px-4">
      {
        // successful message
        trxId ? (
          <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 mb-4 rounded relative" role="alert">
            <strong className="font-bold">Success!</strong>
            <span className="block sm:inline">Please update billing details and try again!</span>
            <button
              className="absolute top-0 bottom-0 right-0 px-4 py-3"
              onClick={() => {
                window.location.href = `${window.location.origin}/settings/plan`;
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        ) : message === 'update_billing_success' ? (
          <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 mb-4 rounded relative" role="alert">
            <strong className="font-bold">Success!</strong>
            <span className="block sm:inline">
              Billing details updated successfully! Update your plan now. please wait 3s.
            </span>
            <button
              className="absolute top-0 bottom-0 right-0 px-4 py-3"
              onClick={() => {
                window.location.href = `${window.location.origin}/settings/plan`;
              }}
            >
              <Cross className="h-6 w-6 text-red-500" />
            </button>
          </div>
        ) : message === 'success' ? (
          <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 mb-4 rounded relative" role="alert">
            <strong className="font-bold">Success!</strong>
            <span className="block sm:inline">Plan updated successfully!. please wait 3s.</span>
            <button
              className="absolute top-0 bottom-0 right-0 px-4 py-3"
              onClick={() => {
                window.location.href = `${window.location.origin}/settings/plan`;
              }}
            >
              <Cross className="h-6 w-6 text-red-500" />
            </button>
          </div>
        ) : null
      }
      {user?.workspace?.plan === 'pay_as_you_go' && user?.workspace?.payAsYouGoPaymentError ? (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-6" role="alert">
          <strong className="font-bold">Error!</strong>
          <span className="block sm:inline">
            Plan suspended. Payment failed. Reason: {user?.workspace?.suspensionReason}
          </span>
          <button
            className="absolute top-0 bottom-0 right-0 px-4 py-3"
            onClick={() => {
              handlePayAsYouGoPayment();
            }}
          >
            Pay now
          </button>
        </div>
      ) : null}
      <div className="mb-6">
        <h4 className="text-[#101828] text-lg font-semibold font-inter leading-7">Pricing Plans</h4>
        <p className="text-slate-600 text-base font-normal leading-normal m-0">Manage your plans from here.</p>
      </div>

      <div>
        <div className="grid lg:grid-cols-3 grid-cols-1 gap-6">
          {/* Free Plan */}
          <div className="p-6 border border-[#1A3300] rounded-2xl flex flex-col justify-between gap-6">
            <div className="space-y-6">
              <img src="/image/icons/gift.png" alt="" width={60} height={65} className="" />
              <h5 className="font-bold font-inter text-2xl">
                Free
              </h5>
              <div className="flex items-center gap-2">
                <h4 className="font-bold font-inter text-5xl">
                  {PLANS[0].price}
                </h4>
                <p className="text-2xl">
                  per seat
                </p>
              </div>
              <p className="text-base font-normal">
                for individuals with less than two calls per month.
              </p>

              {user?.workspace?.plan === PLANS[0].id ? (
                <Button
                  variant="outline"
                  className="w-full text-[#1A3300] border-[#1A3300] font-semibold text-lg"
                  disabled
                >
                  Active
                </Button>
              ) : (
                <Button
                  variant="outline"
                  className="w-full text-[#1A3300] hover:bg-[#1A3300] hover:text-[#FCEB5B] border-[#1A3300] font-semibold text-lg"
                  disabled={loading}
                  onClick={() => handleSwitchToFreePlan()}
                >
                  {loading ? 'Updating...' : 'Switch To Free'}
                </Button>
              )}

              <ul className="list-disc pl-5 text-base font-normal space-y-3 mb-[50px]">
                <li>45 monthly free minutes</li>
                <li>Up to 10 participants</li>
                <li>Up to 2 project briefs</li>
                <li>Up to 2 highlights/summaries</li>
                <li>Create project space</li>
                <li>Unlimited meeting storage</li>
                <li>Calendar client</li>
                <li>Scheduler</li>
                <li>Brief Editor</li>
                <li>Real-time subtitles</li>
              </ul>
            </div>
            <div>
              <hr className="border-[#1A3300] mb-6" />
              <p className="text-center font-medium text-base font-inter text-[#1A3300]">
                45 free minutes, renewed monthly
              </p>
            </div>
          </div>

          {/* Solopreneurs Plan */}
          <div className="p-6 border border-[#1A3300] bg-[#FCEB5B] rounded-2xl flex flex-col gap-6 justify-between">
            <div className="space-y-6">
              <img src="/image/icons/rocket.png" alt="" width={62} height={62} />
              <h5 className="font-bold font-inter text-2xl">
                Solopreneurs
              </h5>
              <div className="flex items-center gap-2">
                <h4 className="font-bold font-inter text-5xl">
                  {PLANS[1].price}
                </h4>
                <p className="text-2xl">
                  per seat
                </p>
              </div>
              <p className="text-base font-normal">
                Best for freelancers & solo founders/creators.
              </p>

              {user?.workspace?.plan === PLANS[1].id ? (
                <Button
                  variant="outline"
                  className="w-full text-[#1A3300] border-[#1A3300] font-semibold text-lg"
                  disabled
                >
                  Active
                </Button>
              ) : (
                <div>
                  {user?.workspace?.paddleSubscriptionId && (
                    <input
                      type="number"
                      value={changePlanWithSeats}
                      onChange={(e) => setChangePlanWithSeats(e.target.value)}
                      className="border rounded-lg p-2 mb-4 bg-transparent text-[#1A3300] border-[#1A3300] w-full font-bold"
                    />
                  )}
                  <Button
                    variant="outline"
                    disabled={loading}
                    onClick={() => {
                      if (user?.workspace?.paddleSubscriptionId) {
                        handleChangePlan(PLANS[1].planId, user?.workspace?.seats || 0);
                      } else {
                        handleCheckout(PLANS[1].planId, 0);
                      }
                    }}
                    className="w-full text-[#1A3300] bg-transparent hover:bg-[#1A3300] hover:text-[#FCEB5B] border-[#1A3300] font-semibold text-lg"
                  >
                    {loading ? 'Updating...' : 'Switch To Solopreneurs'}
                  </Button>
                </div>
              )}

              <ul className="list-disc pl-5 text-base font-normal space-y-3 mb-[50px]">
                <li><strong>600 monthly minutes</strong></li>
                <li><strong>Up to 25 participants</strong></li>
                <li><strong>Unlimited project briefs</strong></li>
                <li><strong>Unlimited highlights/summaries</strong></li>
                <li>Create project space</li>
                <li>Unlimited meeting storage</li>
                <li>Calendar client</li>
                <li>Scheduler</li>
                <li>Brief Editor</li>
                <li>Real-time subtitles</li>
              </ul>
            </div>
            <div>
              <hr className="border-[#1A3300] mb-6" />
              <p className="text-center font-medium text-base font-inter text-[#1A3300]">
                $1.99/hour after package minutes.
              </p>
            </div>
          </div>

          {/* Teams Plan */}
          <div className="p-6 border border-[#1A3300] bg-[#1A3300] text-white rounded-2xl flex flex-col justify-between gap-6">
            <div className="space-y-6">
              <img src="/image/icons/clap.png" alt="" width={65} height={72} />
              <div className="flex items-center">
                <h5 className="font-bold font-inter text-2xl text-white">
                  Teams
                </h5>
                <span className="font-black text-xs px-2 py-0.5 text-[#1A3300] bg-[#FCEB5B] rounded-full ml-2">
                  Popular
                </span>
              </div>
              <div className="flex items-center gap-2">
                <h4 className="font-bold font-inter text-5xl text-white">
                  {PLANS[2].price}
                </h4>
                <p className="text-2xl">
                  per seat
                </p>
              </div>
              <p className="text-base font-normal">
                Best for agencies/studios & startups with small teams
              </p>

              {user?.workspace?.plan === PLANS[2].id ? (
                <Button
                  variant="outline"
                  className="w-full text-[#FCEB5B] border-[#FCEB5B] font-semibold text-lg"
                  disabled
                >
                  Active
                </Button>
              ) : (
                <div>
                  {user?.workspace?.paddleSubscriptionId && (
                    <input
                      type="number"
                      value={changePlanWithSeats}
                      onChange={(e) => setChangePlanWithSeats(e.target.value)}
                      className="border rounded-lg p-2 mb-4 bg-transparent text-[#FCEB5B] border-[#FCEB5B] w-full font-bold"
                    />
                  )}
                  <Button
                    variant="outline"
                    disabled={loading}
                    onClick={() => {
                      if (user?.workspace?.paddleSubscriptionId) {
                        handleChangePlan(PLANS[2].planId, user?.workspace?.seats || 0);
                      } else {
                        handleCheckout(PLANS[2].planId, 0);
                      }
                    }}
                    className="w-full text-[#FCEB5B] hover:bg-[#FCEB5B] bg-transparent border-[#FCEB5B] font-semibold text-lg"
                  >
                    {loading ? 'Updating...' : 'Switch To Teams'}
                  </Button>
                </div>
              )}

              <ul className="list-disc pl-5 text-base font-normal space-y-3 mb-4">
                <li><strong className="text-[#FCEB5B]">1800 monthly minutes</strong></li>
                <li><strong className="text-[#FCEB5B]">Up to 100 participants</strong></li>
                <li><strong className="text-[#FCEB5B]">Unlimited project briefs</strong></li>
                <li><strong className="text-[#FCEB5B]">Unlimited highlights/summaries</strong></li>
                <li><strong className="text-[#FCEB5B]">Manage teamspaces</strong></li>
                <li>Unlimited meeting storage</li>
                <li>Calendar client</li>
                <li>Scheduler</li>
                <li>Brief Editor</li>
                <li>Real-time subtitles</li>
              </ul>
            </div>

            <div>
              <hr className="border-white mb-6" />
              <p className="text-center font-medium text-base font-inter">
                $1.99/hour after package minutes.
              </p>
            </div>
          </div>
        </div>

        {/* Enterprise Section */}
        <div className="border-2 border-[#1A3300] rounded-2xl p-8 flex flex-col lg:flex-row lg:items-end my-8 gap-8">
          <img src="/image/icons/building.png" alt="" width={100} height={115} />
          <div className="flex-grow">
            <h4 className="font-bold lg:text-2xl text-lg font-inter mb-2">
              Enterprise
            </h4>
            <p className="text-base font-normal">
              Our Enterprise plans include features like Single Sign-On (SSO),
              Data Retention Policies, Custom Branding, and Advanced Controls for teams of 100 or more. To learn more, simply fill out the form.
            </p>
          </div>
          <Button
            className="px-12"
            onClick={() => window.open(PLANS[3].contact_link, '_blank')}
          >
            Contact Sales
          </Button>
        </div>
      </div>

      {/* <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {PLANS.map((plan) => (
          <div key={plan.name} className="border border-gray-300 rounded-lg p-6 shadow-md">
            <h3 className="text-2xl font-semibold mb-2">{plan.name}</h3>
            <p className="text-3xl font-bold mb-4">{plan.price}</p>
            <p className="text-gray-600 mb-4">{plan.description}</p>
            <ul className="mb-6">
              {plan.features.map((feature, index) => (
                <li key={index} className="text-gray-800 mb-2">
                  {feature}
                </li>
              ))}
            </ul>
            {user?.workspace?.plan === plan?.id ? (
              <>
                <p className="text-gray-800 mb-2">
                  Status: <span className="text-green-500">Active</span>
                </p>
                {!['free', 'pay_as_you_go'].includes(user?.workspace?.plan) && (
                  <>
                    <input
                      type="number"
                      value={selectedSeats}
                      onChange={(e) => setSelectedSeats(e.target.value)}
                      className="border border-gray-300 rounded-lg p-2 mb-4"
                    />
                    <button
                      disabled={loading}
                      onClick={() => handleSeatsChange(plan.planId, 1, true)}
                      className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
                    >
                      {loading ? 'Updating...' : 'Add More Seats'}
                    </button>
                  </>
                )}
              </>
            ) : plan?.contact_link ? (
              <a
                href={plan.contact_link}
                target="_blank"
                rel="noopener noreferrer"
                className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
              >
                Contact Us
              </a>
            ) : plan?.id !== 'free' ? (
              <>
                {user?.workspace?.paddleSubscriptionId && plan?.id !== 'pay_as_you_go' ? (
                  <input
                    type="number"
                    value={changePlanWithSeats}
                    onChange={(e) => setChangePlanWithSeats(e.target.value)}
                    className="border border-gray-300 rounded-lg p-2 mb-4"
                  />
                ) : null}
                <button
                  disabled={loading}
                  onClick={() => {
                    if (user?.workspace?.paddleSubscriptionId) {
                      handleChangePlan(plan.planId, user?.workspace?.seats || 0);
                    } else {
                      handleCheckout(plan.planId, 0);
                    }
                  }}
                  className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
                >
                  {loading ? 'Updating...' : 'Upgrade'}
                </button>
              </>
            ) : (
              <button
                disabled={loading}
                onClick={() => handleSwitchToFreePlan()}
                className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
              >
                {loading ? 'Updating...' : 'Switch To Free'}
              </button>
            )}
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default PricingPlan;
