/* eslint-disable indent */
import axios from 'axios';
import Config from '../config';

const getResourcesByUserEmails = (guestEmails) => {
    return axios({
        method: 'POST',
        url: `${Config.url || ''}/api/v1/user/get_resource_by_user_emails`,
        data: {
            user_emails: guestEmails,
        },
    });
};

export default getResourcesByUserEmails;
