/* eslint-disable indent */
import { DialogTitle } from '@radix-ui/react-dialog';
import { Button } from '../../../../../components/ui/button';
import {
    Dialog,
    DialogContent,
    DialogDescription,
} from '../../../../../components/ui/dialog';

function AddGuestModal({
    showAddGuestModal,
    handleTriggerAddGuestModal,
    isLoadingAddGuest,
    name,
    setName,
    email,
    setEmail,
    handleAddGuest,
}) {
    return (
      <Dialog open={showAddGuestModal} onOpenChange={handleTriggerAddGuestModal}>
        <DialogContent className="bg-[#EAECF0] max-w-[400px] py-8 px-6">
          <DialogTitle>Add Guest</DialogTitle>
          <DialogDescription className="hidden" />
          <div className="space-y-4 flex-grow flex flex-col justify-center">
            <label htmlFor="name" className="space-y-1 block">
              <span className="text-slate-700 text-sm font-medium leading-tight">Full name</span>
              <input
                type="text"
                placeholder="Full name"
                name="name"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-full block py-2.5 px-4 outline-none bg-gray-50 rounded-md border border-slate-300 focus:ring-0 focus:outline-none"
              />
            </label>
            <label htmlFor="email" className="space-y-1 block">
              <span className="text-slate-700 text-sm font-medium leading-tight flex items-center gap-[6px]">
                Email
              </span>
              <input
                type="email"
                placeholder="guest@company.com"
                name="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full block py-2.5 px-4 outline-none bg-gray-50 rounded-md border border-slate-300 focus:ring-0 focus:outline-none"
              />
            </label>
            <Button onClick={() => handleAddGuest(name, email)} className="bg-black">
              {isLoadingAddGuest ? (
                <span
                  className="border-2 border-white border-r-transparent rounded-full h-4 w-4 animate-spin"
                />
                        ) : 'Add Guest'}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    );
}

export default AddGuestModal;
