import axios from 'axios';
import Config from '../config';

const getMeetingByCode = (id) => {
  return axios({
    method: 'get',
    url: `${Config.url || ''}/api/v1/user/meeting/code/${id}`,
    data: {},
  });
};

export default getMeetingByCode;
