/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
/* eslint-disable object-curly-newline */
import { useSelector } from 'react-redux';
import Actions from '../../../constants/Actions';

export default function MeetingPeople({ streams, roomID, meeting, user, dispatch, io }) {
    const consumers = useSelector((state) => state.rtc.consumers);
    const producers = useSelector((state) => state.rtc.producers);
    const peers = useSelector((state) => state.rtc.peers);
    const socketID = useSelector((state) => state.io.id);

    const actualConsumers = consumers.filter((c) => c !== socketID);
    let actualPeers = [];
    actualConsumers.forEach((consumerID) => {
        const actualPeer = {
            ...peers[consumerID],
            video: null,
            audio: null,
            screen: null,
        };
        const peerStreams = streams.filter((s) => s.socketID === consumerID);
        peerStreams.forEach((stream) => {
            actualPeer.streams = [...(actualPeer.streams || []), stream];
            if (stream.isVideo) return (actualPeer.video = stream);
            actualPeer.audio = stream;
        });
        const isScreen = (actualPeer.video || actualPeer.screen)
            && producers.filter((p) => p.producerID === actualPeer.video.producerID && p.isScreen).length > 0;
        actualPeers.push({ ...actualPeer, isScreen });
    });

    actualPeers = actualPeers.filter((peer) => peer.requestJoin === true).sort((a) => (((a.audio !== null) || (a.screen !== null) || (a.video !== null)) ? -1 : 1));

    const acceptAll = () => {
        Object.keys(actualPeers)?.forEach(async (peerKey) => {
            if (actualPeers[peerKey]?.requestJoin === true && actualPeers[peerKey]?.userID !== meeting?.meeting?.createdBy?._id) {
                const data = await io.request('approve_join', { roomID, socketID: actualPeers[peerKey]?.socketID, userID: actualPeers[peerKey]?.user?._id });
                if (data?.message) {
                    // alert(data?.message);
                    dispatch({
                        type: Actions.RTC_UPDATE_PEER,
                        data: { ...actualPeers[peerKey], requestJoin: false, approveJoin: true },
                    });
                }
            }
        });
    };

    const rejectAll = () => {
        Object.keys(actualPeers)?.forEach(async (peerKey) => {
            if (actualPeers[peerKey]?.requestJoin === true && actualPeers[peerKey]?.userID !== meeting?.meeting?.createdBy?._id) {
                const data = await io.request('reject_join', { roomID, socketID: actualPeers[peerKey]?.socketID, userID: actualPeers[peerKey]?.user?._id });
                if (data?.message) {
                    // alert(data?.message);
                    dispatch({
                        type: Actions.RTC_UPDATE_PEER,
                        data: { ...actualPeers[peerKey], requestJoin: false, approveJoin: false },
                    });
                }
            }
        });
    };

    return actualPeers?.[Object.keys(actualPeers)?.filter((peerKey) => actualPeers[peerKey]?.userID.toString() !== meeting?.meeting?.createdBy?._id && actualPeers[peerKey]?.requestJoin === true)?.[0]] && (meeting?.meeting?.createdBy?._id?.toString() === user?.id?.toString() || meeting?.meeting?.createdBy?._id?.toString() === user?._id?.toString()) && (
        <div className="max-w-[285px] absolute bottom-24 right-6 p-6 bg-[#ffffffbe] rounded-lg backdrop-blur-[5px] space-y-2.5 z-[999999999999]">
            {/*  */}
            <div className="flex items-center gap-[8px] py-2.5 w-full border-b border-[#D2D2D2]">
                <button className="absolute top-2.5 right-2.5">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                    </svg>
                </button>
                <p className="text-black text-xl font-semibold leading-[30px] m-0">Waiting Room</p>
            </div>
            <div className="h-[166px] overflow-y-auto text-black text-base font-normal font-['Inter'] leading-normal">
                <div className="flex justify-between items-center my-2 mb-4">
                    <button
                        onClick={acceptAll}
                        className="cursor-pointer p-2 rounded-xl justify-center items-center flex bg-black text-white text-xs"
                    >
                        Approve All
                    </button>

                    <button
                        onClick={rejectAll}
                        className="cursor-pointer p-2 rounded-xl justify-center items-center flex bg-black text-white text-xs"
                    >
                        Reject All
                    </button>
                </div>
                {/* <img /> */}
                {Object.keys(actualPeers).map((element) => (
                    actualPeers[element]?.requestJoin === true && actualPeers[element]?.userID !== meeting?.meeting?.createdBy?._id && (
                        <div key={actualPeers[element]?.socketID} className="flex items-center justify-between gap-2 py-2 mb-2 border-b border-black">
                            <p>
                                {`${actualPeers[element]?.user?.firstName} ${actualPeers[element]?.user?.lastName}`}
                            </p>
                            <div className="pl-4 flex gap-2">
                                <button
                                    onClick={async () => {
                                        const data = await io.request('approve_join', { roomID, socketID: actualPeers[element]?.socketID, userID: actualPeers[element]?.user?._id });
                                        if (data?.message) {
                                            // alert(data?.message);
                                            dispatch({
                                                type: Actions.RTC_UPDATE_PEER,
                                                data: { ...actualPeers[element], requestJoin: false, approveJoin: true },
                                            });
                                        }
                                    }}
                                    className="cursor-pointer p-1 rounded-xl justify-center items-center flex bg-black text-white text-xs"
                                >
                                    Approve
                                </button>
                                <button
                                    onClick={async () => {
                                        const data = await io.request('reject_join', { roomID, socketID: actualPeers[element]?.socketID, userID: actualPeers[element]?.user?._id });
                                        if (data?.message) {
                                            // alert(data?.message);
                                            dispatch({
                                                type: Actions.RTC_UPDATE_PEER,
                                                data: { ...actualPeers[element], requestJoin: false, approveJoin: false },
                                            });
                                        }
                                    }}
                                    className="cursor-pointer p-1 px-2 rounded-xl justify-center items-center flex bg-black text-white text-xs"
                                >
                                    X
                                </button>
                            </div>
                        </div>
                    )
                ))}
            </div>
        </div>
    );
}
