/* eslint-disable no-useless-escape */
/* eslint-disable operator-linebreak */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useGlobal } from 'reactn';
import getMeetingHistory from '../../../actions/getMeetingHistory';
import getMeetingsByUserEmail from '../../../actions/getMeetingsByUserEmail';
import getUserSuggestion from '../../../actions/getUserSuggestion';
import { Button } from '../../../components/ui/button';
import { Checkbox } from '../../../components/ui/checkbox';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../../../components/ui/dialog';
import Config from '../../../config';
import MeetingHistoryItem from './meeting-history-item';

const validateEmail = (email) => {
  const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return pattern.test(email);
};

function AddProjectSpace({ setProjectSpaceId = false, userEmails = [] }) {
  const [open, setOpen] = useState(false);
  const { addToast } = useToasts();
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [refreshProjects, setRefreshProjects] = useGlobal('refreshProjects');
  const [userSuggestion, setUserSuggestion] = useState([]);

  const [meetingHistory, setMeetingHistory] = useState([]);

  const [selectedMeetings, setSelectedMeetings] = useState([]);
  const [isLoadingHistory, setIsLoadingHistory] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoadingHistory(true);
      try {
        const { data } = await getMeetingHistory();
        setMeetingHistory(data?.data);
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoadingHistory(false);
      }
    };

    if (email === '') {
      getData();
    }
  }, [email]);

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await getUserSuggestion(email);
        setUserSuggestion(data.data);
      } catch (err) {
        console.log(err);
        setUserSuggestion([]);
      }
    };
    if (email) {
      getData();
    } else {
      setUserSuggestion([]);
    }
  }, [email]);

  const handleCreateSpace = async () => {
    if (!name || !email) return;

    setIsSubmitLoading(true);

    try {
      const res = await axios.post(`${Config.url || ''}/api/v1/user/add_resource`, {
        name,
        user_emails: userEmails,
        user_email: email,
        meetingIds: selectedMeetings,
      });
      addToast(res.data.message, { appearance: 'success' });
      setName('');
      setEmail('');
      setOpen(false);
      setRefreshProjects(!refreshProjects);
      if (setProjectSpaceId) {
        setProjectSpaceId(res.data?.data?._id);
      }
    } catch (err) {
      if (err?.response?.data?.message) {
        addToast(err?.response?.data?.message, { appearance: 'error' });
      } else {
        addToast(err?.response?.data?.error || 'Something went wrong', { appearance: 'error' });
      }
    } finally {
      setIsSubmitLoading(false);
    }
  };

  const handleMeetingsByUserEmail = async (emailInput) => {
    setEmail(emailInput);
    if (!emailInput) return;
    console.count(emailInput);

    if (!validateEmail(emailInput)) return;

    setIsLoadingHistory(true);
    try {
      const { data } = await getMeetingsByUserEmail(emailInput);
      setMeetingHistory(data?.data);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoadingHistory(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <div className="size-full cursor-pointer px-[49px] py-[54px] bg-[#f2eeee] rounded-2xl flex-col justify-center items-center gap-2.5 inline-flex">
          <svg width="33" height="34" viewBox="0 0 33 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="plus-circle">
              <path
                id="Icon"
                d="M16.5 11.1846V22.1846M11 16.6846H22M30.25 16.6846C30.25 24.2785 24.0939 30.4346 16.5 30.4346C8.90608 30.4346 2.75 24.2785 2.75 16.6846C2.75 9.09065 8.90608 2.93457 16.5 2.93457C24.0939 2.93457 30.25 9.09065 30.25 16.6846Z"
                stroke="#8A8A8A"
                strokeWidth="2.75"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </svg>

          <div className="w-[145.50px] text-center text-[#898989] text-base font-semibold font-inter leading-normal">
            Create a new space
          </div>
        </div>
      </DialogTrigger>
      <DialogContent className="min-w-max p-[32px 30px] pt-[12px]">
        <DialogHeader>
          <DialogTitle className="hidden">Edit profile</DialogTitle>
          <DialogDescription className="hidden" />
        </DialogHeader>
        <div className="px-3 py-[22px] bg-[#eeeeee] rounded-2xl">
          <div className="space-y-4 flex-grow flex flex-col justify-center">
            <label htmlFor="name" className="space-y-1 block">
              <span className="text-slate-700 text-sm font-medium leading-tight">Project Space Name</span>
              <input
                type="text"
                placeholder="Name"
                name="name"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-full block py-2.5 px-4 outline-none bg-gray-50 rounded-md border border-slate-300 focus:ring-0 focus:outline-none"
              />
            </label>
            <label htmlFor="email" className="space-y-1 block relative">
              <span className="text-slate-700 text-sm font-medium leading-tight flex items-center gap-[6px]">
                Clients Email
              </span>
              <input
                type="email"
                placeholder="client@company.com"
                name="email"
                id="email"
                value={email}
                autoComplete="off"
                onChange={(e) => handleMeetingsByUserEmail(e.target.value)}
                className="w-full block py-2.5 px-4 outline-none bg-gray-50 rounded-md border border-slate-300 focus:ring-0 focus:outline-none"
              />

              {
                userSuggestion.length > 0 && !validateEmail(email) && (
                  <div className="absolute w-full bg-white border top-[95%] left-0 rounded py-2 z-[50]">
                    {userSuggestion.map((user) => (
                      <button
                        key={user._id}
                        className="py-1.5 px-3 text-sm hover:bg-gray-50 cursor-pointer w-full flex text-left truncate"
                        onClick={() => handleMeetingsByUserEmail(user.contact_user.email)}
                      >
                        {user.contact_user.email}
                      </button>
                    ))}
                  </div>
                )
              }
            </label>

            {
              isLoadingHistory ? (<span className="size-4 border-2 border-black rounded-full border-t-gray-300 animate-spin mx-auto mt-4" />) : meetingHistory?.length > 0 && (
                <>
                  <div className="text-[#344054] text-sm font-medium font-['Inter'] leading-tight">
                    Select Previous Meeting associated with this space
                  </div>
                  <div className={`max-h-[260px] overflow-y-auto ${isLoadingHistory ? 'animate-pulse' : ''}`}>
                    <div className="space-y-3">
                      {meetingHistory?.map((meeting) => (
                        <div className="flex gap-2 items-center" key={meeting?._id}>
                          <Checkbox
                            id={meeting?._id}
                            className="border-[#d0d5dd]"
                            checked={selectedMeetings.includes(meeting?._id)}
                            onCheckedChange={(val) => {
                              if (val) {
                                setSelectedMeetings([...selectedMeetings, meeting?._id]);
                              } else {
                                setSelectedMeetings(selectedMeetings.filter((id) => id !== meeting?._id));
                              }
                            }}
                          />

                          <label htmlFor={meeting?._id} className="w-full">
                            <MeetingHistoryItem data={meeting} maxAvatar={1} />
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              )
            }

          </div>
        </div>
        <div>
          <Button onClick={handleCreateSpace}>
            {isSubmitLoading ? (
              <span className="border-2 border-white border-r-transparent rounded-full h-4 w-4 animate-spin" />
            ) : (
              'Create'
            )}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default AddProjectSpace;
