/* eslint-disable react/jsx-indent-props */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import { useSelector } from 'react-redux';
import { useState, useEffect, useRef } from 'react';
import config from '../../../config';
import { getGravatarUrl } from '../../../lib/utils';

function RoomCaptions() {
    const room = useSelector((state) => state.io.room);
    // const currentTime = new Date().getTime();
    const reorderDelay = 1000; // Delay in ms for reordering users

    // State to store current segments for each user
    const [segmentsMap, setSegmentsMap] = useState({});
    // State to control the order of displayed users with a delay
    const [displayOrder, setDisplayOrder] = useState([]);
    const reorderTimeoutRef = useRef(null);

    const captions = room?.captions;

    // Update segments immediately whenever a new caption arrives
    useEffect(() => {
        if (!captions?.length) return;

        const updatedSegments = { ...segmentsMap };
        const currentActiveTime = new Date().getTime();

        captions.forEach((caption) => {
            const timeDiff = (currentActiveTime - caption.timestamp) / 1000;
            if (timeDiff < 3 && caption.transcription?.segments?.length > 0) {
                const userId = caption.user._id;
                updatedSegments[userId] = {
                    ...caption,
                    transcription: { segments: [caption.transcription.segments.slice(-1)[0]] }, // Only last segment
                };
            }
        });

        setSegmentsMap(updatedSegments);

        // Set a delayed reordering for displayOrder to avoid rapid reordering
        if (!reorderTimeoutRef.current) {
            reorderTimeoutRef.current = setTimeout(() => {
                const recentSpeakers = Object.values(updatedSegments)
                    .filter((caption) => (currentActiveTime - caption.timestamp) / 1000 < 3) // Only include recent speakers
                    .sort((a, b) => a.timestamp - b.timestamp) // Sort by timestamp (oldest first)
                    .slice(-2); // Keep only the last two users

                setDisplayOrder(recentSpeakers);
                reorderTimeoutRef.current = null;
            }, reorderDelay);
        }
    }, [captions]);

    // Cleanup timeout on unmount
    useEffect(() => {
        return () => {
            if (reorderTimeoutRef.current) {
                clearTimeout(reorderTimeoutRef.current);
            }
        };
    }, []);

    return (
        displayOrder.length > 0 && (
            <div className="flex flex-col p-4 rounded-xl mx-40 bg-opacity-75 bg-black text-white w-full">
                {displayOrder.map((group) => (
                    <div key={group.user._id} className="flex items-start gap-2 py-1">
                        <img
                            src={group.user?.picture?.location ? `${config.url}/${group.user.picture.location}` : getGravatarUrl(`${group.user?.firstName} ${group.user?.lastName}`, group.user?.email, 300)}
                            className="w-10 h-10 rounded-full"
                            alt={`${group.user.firstName}'s avatar`}
                        />
                        <div>
                            <span className="font-bold">{group.user.firstName}: </span> <br />
                            <span>
                                {segmentsMap[group.user._id]?.transcription.segments.map((segment, segIndex) => (
                                    <span key={segIndex}>{segment.text} </span>
                                ))}
                            </span>
                        </div>
                    </div>
                ))}
            </div>
        )
    );
}

export default RoomCaptions;
