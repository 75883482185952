import axios from 'axios';
import Config from '../config';

const addWorkspaceMember = (workspaceId, email) => {
  return axios({
    method: 'post',
    url: `${Config.url || ''}/api/v1/user/workspace/add_workspace_member/${workspaceId}`,
    data: { user_email: email },
  });
};

export default addWorkspaceMember;
