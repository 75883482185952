import {
  NavLink, Outlet,
} from 'react-router-dom';
import PageHeading from '../../components/page-heading';
import Layout from '../../layout';

const navigation = [
  {
    label: 'Scheduling',
    href: './scheduling',
  },
  {
    label: 'Form Builder',
    href: './form-builder',
  },
  // {
  //   label: 'Design',
  //   href: './design',
  // },
];

function BackOfficePage() {
  return (
    <Layout>
      <div className="space-y-6">
        <PageHeading title="Back Office" />
        <ul className="flex pl-0">
          {navigation.map((item, i) => (
            <li key={i}>
              <NavLink
                to={item?.href}
                className={({ isActive }) => `px-4 py-[12px] hover:text-gray-900 ${isActive ? 'text-gray-900 border-b-2 border-black' : 'text-slate-700  border-b'}`}
              >
                {item.label}
              </NavLink>
            </li>
          ))}
        </ul>
        <div>
          <Outlet />
        </div>
      </div>
    </Layout>
  );
}

export default BackOfficePage;
