/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable import/no-unresolved */
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import { Button } from '../../../../../components/ui/button';
import { Input } from '../../../../../components/ui/input';
import Config from '../../../../../config';
// import { formatDateString } from '../../../../../lib/utils';
import MeetingScheduleSuccessModal from '../../../components/meeting-schedule-success-modal';
import { convertTo12Hour } from '../../../../../lib/utils';

function FormSubmission({
  fields,
  setFields,
  scheduleId,
  bookingId,
  date,
  selectedTime,
  selectedTimezone,
  meetingId,
  isFormAdded,
  formSubmissionObj,
  username,
  booking,
  schedule,
}) {
  const [activeIndex, setActiveIndex] = useState(1);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const navigate = useNavigate();

  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleRestartSteps = () => {
    setActiveIndex(1);
    setFields((prev) =>
      prev.map((field) => {
        return {
          ...field,
          answer: '',
        };
      }),
    );
  };

  const handleSubmitScheduleForm = () => {
    const fieldsData = fields.map((field) => {
      return {
        fieldId: field._id,
        answer: field.answer,
      };
    });

    // formData.append('fields', JSON.stringify(fieldsData));

    const formData = {
      scheduleId,
      bookingId,
      // date: formatDateString(date),
      // time: selectedTime,
      fields: fieldsData,
    };

    const config = {
      method: 'post',
      url: `${Config.url || ''}/api/v1/public/submit_booking_form`,
      data: formData,
    };

    //   show error if any of them are empty
    // if (!selectedTime) {
    //   toast.error('Please select a time.', {
    //     position: 'top-center',
    //   });
    //   return;
    // }

    setIsSubmitLoading(true);
    axios
      .request(config)
      .then((data) => {
        // console.log(data?.data?.message);
        toast.success(data?.data?.message, {
          position: 'top-center',
        });
        setShowSuccessModal(true);
        // setStep(0);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error, {
          position: 'top-center',
        });
        setShowSuccessModal(true);
        // console.log(err.response.data.error);
      })
      .finally(() => {
        setIsSubmitLoading(false);
      });
  };

  useEffect(() => {
    if (isFormAdded && !booking?.preMeetingFormRequired) {
      setShowSuccessModal(true);
    }
  }, [isFormAdded]);

  const isPrivate = booking?.submittedType === 'private';
  return (
    <div>
      {booking?.preMeetingFormRequired && (
        <div className="bg-[#E2E8F0] rounded-lg overflow-hidden min-w-5xl min-h-[500px] shadow-md flex flex-col">
          {!isPrivate && (
            <div className="bg-[#F1F5F9] w-full h-full flex items-center justify-end gap-2 px-4 py-2">
              <button
                onClick={handleRestartSteps}
                className="px-2 py-0.5 bg-white rounded justify-center gap-[4.29px] flex"
              >
                <p className="m-0 text-center text-slate-500 text-[12px] font-medium leading-3">Restart</p>
                <img
                  src="/image/icons/repeat.svg"
                  alt=""
                  className="w-[12px] h-[12px] relative flex-col justify-start items-start flex"
                />
              </button>
            </div>
          )}

          <div className="flex-grow flex justify-center items-center p-20">
            {isPrivate ? (
              <div className="p-6 bg-white shadow-lg rounded-lg min-w-[400px] space-y-6">
                {booking && schedule && meetingId && date && selectedTime && (
                  <div className="bg-white p-3 py-4 rounded-2xl space-y-6">
                    <h4 className="text-gray-900 text-lg font-semibold leading-7">Meeting Details</h4>
                    <div className="grid grid-cols-1 gap-y-6">
                      <div className="space-y-6">
                        <div className="space-y-[10px]">
                          <p className="text-slate-600 text-sm font-semibold leading-tight m-0">What</p>
                          <p className="text-slate-600 text-sm font-normal leading-tight m-0 capitalize">
                            {booking?.bookingTimeSlot} Meeting
                          </p>
                        </div>
                        <div className="space-y-[10px]">
                          <p className="text-slate-600 text-sm font-semibold leading-tight m-0">When</p>
                          <p className="text-slate-600 text-sm font-normal leading-tight m-0">
                            {date?.toDateString()}
                            {' - '}
                            {convertTo12Hour(selectedTime)} ({selectedTimezone})
                          </p>
                        </div>
                      </div>
                      <div className="space-y-6">
                        <div className="space-y-[10px]">
                          <p className="text-slate-600 text-sm font-semibold leading-tight m-0">Who</p>
                          <p className="text-slate-600 text-sm font-normal leading-tight flex items-center gap-2.5 m-0">
                            {schedule?.user?.firstName} {schedule?.user?.lastName}
                            <button className="px-2.5 py-0.5 bg-gray-50 rounded-md border border-gray-200 text-center text-slate-700 text-sm font-medium leading-tight">
                              Host
                            </button>
                          </p>
                          <p className="text-slate-600 text-sm font-normal leading-tight">{schedule?.user?.email}</p>
                        </div>
                        {booking?.guests.map((item, i) => (
                          <div className="space-y-[10px]" key={i}>
                            <p className="text-slate-600 text-sm font-normal leading-tight flex items-center gap-2.5 m-0">
                              {item?.name}{' '}
                              <button className="px-2.5 py-0.5 bg-gray-50 rounded-md border border-gray-200 text-center text-slate-700 text-sm font-medium leading-tight">
                                Guest
                              </button>
                            </p>
                            <p className="text-slate-600 text-sm font-normal leading-tight">{item?.email}</p>
                          </div>
                        ))}
                      </div>
                      <div />
                    </div>
                  </div>
                )}
                {fields.map((field, index) => (
                  <div key={index} className="space-y-2">
                    <label htmlFor={field.id} className="text-slate-900 text-sm font-semibold leading-none">
                      {field.question || 'Who let the dogs out ?'}
                      {field.required && <span className="text-red-500"> *</span>}
                    </label>
                    {field.fieldType === 'textarea' ? (
                      <textarea
                        className="w-full border focus:outline-none p-2 rounded-lg"
                        placeholder="Type your answer here..."
                        id={field.id}
                        name={field.id}
                        value={field.answer || ''}
                        onChange={(e) => {
                          const newFields = [...fields];
                          newFields[index].answer = e.target.value;
                          setFields(newFields);
                        }}
                      />
                    ) : (
                      <Input
                        type={field.fieldType === 'phone' ? 'tel' : field.fieldType}
                        id={field.id}
                        placeholder="Type your answer here..."
                        name={field.id}
                        value={field.answer || ''}
                        onChange={(e) => {
                          const newFields = [...fields];
                          newFields[index].answer = e.target.value;
                          setFields(newFields);
                        }}
                      />
                    )}
                  </div>
                ))}
                <Button onClick={handleSubmitScheduleForm} disabled={isSubmitLoading}>
                  {isSubmitLoading ? (
                    <span className="border border-white rounded-full h-4 w-4 animate-spin border-t-transparent" />
                  ) : (
                    'Finish'
                  )}
                </Button>
              </div>
            ) : (
              <>
                <div className="flex-grow flex justify-center items-center p-20">
                  {fields.length > 0 && activeIndex > 0 && (
                    <div className="p-6 bg-white shadow-lg rounded-lg min-w-[400px] space-y-6">
                      <label
                        htmlFor={fields[activeIndex - 1].id}
                        className="text-slate-900 text-sm font-semibold leading-none"
                      >
                        {fields[activeIndex - 1].question || 'Who let the dogs out ?'}
                        {fields[activeIndex - 1].required && <span className="text-red-500"> *</span>}
                      </label>
                      {fields[activeIndex - 1].fieldType === 'textarea' ? (
                        <textarea
                          className="w-full border focus:outline-none p-2 rounded-lg"
                          placeholder="Type your answer here..."
                          id={fields[activeIndex - 1].id}
                          name={fields[activeIndex - 1].id}
                          value={fields[activeIndex - 1].answer || ''}
                          onChange={(e) => {
                            const newFields = [...fields];
                            newFields[activeIndex - 1].answer = e.target.value;
                            setFields(newFields);
                          }}
                          onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                              e.preventDefault();
                              if (!fields[activeIndex - 1].answer.trim()) {
                                toast.error('Please provide an answer before proceeding.', {
                                  position: 'top-center',
                                });
                              } else if (activeIndex < fields.length) {
                                setActiveIndex((prev) => prev + 1);
                              } else {
                                handleSubmitScheduleForm();
                              }
                            }
                          }}
                        />
                      ) : (
                        <Input
                          type={
                            fields[activeIndex - 1].fieldType === 'phone' ? 'tel' : fields[activeIndex - 1].fieldType
                          }
                          id={fields[activeIndex - 1].id}
                          placeholder="Type your answer here..."
                          name={fields[activeIndex - 1].id}
                          value={fields[activeIndex - 1].answer || ''}
                          onChange={(e) => {
                            const newFields = [...fields];
                            newFields[activeIndex - 1].answer = e.target.value;
                            setFields(newFields);
                          }}
                          onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                              e.preventDefault();
                              if (!fields[activeIndex - 1].answer.trim()) {
                                toast.error('Please provide an answer before proceeding.', {
                                  position: 'top-center',
                                });
                              } else if (activeIndex < fields.length) {
                                setActiveIndex((prev) => prev + 1);
                              } else {
                                handleSubmitScheduleForm();
                              }
                            }
                          }}
                        />
                      )}
                      <div className="flex justify-end">
                        {fields.length === activeIndex ? (
                          <Button
                            className={
                              !fields[activeIndex - 1].answer ? 'cursor-not-allowed bg-gray-400 hover:bg-gray-400' : ''
                            }
                            onClick={handleSubmitScheduleForm}
                            disabled={!fields[activeIndex - 1].answer || isSubmitLoading}
                          >
                            {isSubmitLoading ? (
                              <span className="border border-white rounded-full h-4 w-4 animate-spin border-t-transparent" />
                            ) : (
                              'Finish'
                            )}
                          </Button>
                        ) : (
                          <div className="flex items-center gap-2">
                            {activeIndex > 1 && (
                              <Button onClick={() => setActiveIndex((prev) => prev - 1)}>Prev</Button>
                            )}
                            <Button
                              className={
                                !fields[activeIndex - 1].answer
                                  ? 'cursor-not-allowed bg-gray-400 hover:bg-gray-400'
                                  : ''
                              }
                              onClick={() => {
                                if (!fields[activeIndex - 1].answer.trim()) {
                                  toast.error('Please provide an answer before proceeding.', {
                                    position: 'top-center',
                                  });
                                } else {
                                  setActiveIndex((prev) => prev + 1);
                                }
                              }}
                              disabled={!fields[activeIndex - 1].answer}
                            >
                              Next
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      )}

      <MeetingScheduleSuccessModal
        open={showSuccessModal}
        setOpen={(val) => {
          setShowSuccessModal(val);
          navigate('/');
        }}
        date={date?.toDateString()}
        time={selectedTime}
        timeZone={selectedTimezone}
        scheduleId={scheduleId}
        bookingId={bookingId}
        userName={username}
      >
        {booking?.preMeetingFormRequired && (
          <>
            {/*  */}
            <div className="bg-white p-3 py-4 rounded-2xl w-full space-y-6">
              {/*  */}
              <div className="text-gray-900 text-lg font-semibold leading-7">QA Response</div>
              <div className="max-h-[100px] overflow-auto space-y-6">
                {formSubmissionObj?.fields && formSubmissionObj?.fields?.length !== 0
                  ? formSubmissionObj?.fields.map((item, i) => (
                      <div key={i} className="space-y-2.5">
                        <h4 className="text-slate-600 text-sm font-semibold leading-tight mb-0">{item.question}</h4>
                        <p className="text-slate-600 text-sm font-normal leading-tight">
                          {formSubmissionObj.answers[i]}
                        </p>
                      </div>
                    ))
                  : fields.map((item, i) => (
                      <div key={i} className="space-y-2.5">
                        <h4 className="text-slate-600 text-sm font-semibold leading-tight mb-0">{item.question}</h4>
                        <p className="text-slate-600 text-sm font-normal leading-tight">{item.answer}</p>
                      </div>
                    ))}
              </div>
            </div>
          </>
        )}
      </MeetingScheduleSuccessModal>
    </div>
  );
}

export default FormSubmission;
