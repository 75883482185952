/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'sonner';
import { Button } from '../../../../components/ui/button';
import Config from '../../../../config';
import { convertTo24Hour, formatDateString } from '../../../../lib/utils';
import FormSubmission from './components/form-submission';
import Scheduler from './components/scheduler';
import UserForm from './components/user-form';

function GetSchedule() {
  const { username, workspaceID } = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  // const scheduleId = searchParams.get('scheduleId');
  // const formId = searchParams.get('formId');
  const [scheduleId, setScheduleId] = useState('');
  const [formId, setFormId] = useState('');
  const initialBookingId = searchParams.get('bookingId');
  const isVerifyPending = searchParams.get('isVerifyPending');
  const [step, setStep] = useState(0);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  // Scheduler
  const [daysOfWeek, setDaysOfWeek] = useState([]);
  const [selectedTimezone, setSelectedTimezone] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  const [slot, setSlot] = useState(15);

  // Bookings
  const [bookingId, setBookingId] = useState('');
  const [bookings, setBookings] = useState([]);
  const [isFormAdded, setIsFormAdded] = useState(false);
  const [formSubmissionObj, setFormSubmissionObj] = useState({});
  const [booking, setBooking] = useState({});
  const [schedule, setSchedule] = useState({});

  // meeting
  const [meetingId, setMeetingId] = useState('');

  // Answering Forms
  const [fields, setFields] = useState([]);

  const [date, setDate] = useState(new Date());

  // Loading
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (initialBookingId && isVerifyPending === 'true') {
      setBookingId(initialBookingId);
      setStep(1);
    }
    if (initialBookingId && (isVerifyPending === 'false' || !isVerifyPending) && scheduleId) {
      axios
        .get(`${Config.url || ''}/api/v1/public/get_booking/${scheduleId}/${initialBookingId}`)
        .then((data) => {
          const booking = data?.data?.data?.booking;
          setSelectedTime(booking.bookingTime);
          setDate(new Date(booking.bookingDate));
          setMeetingId(booking.meeting?.meetingCode);
          setIsFormAdded(booking?.formAdded);
          setFormSubmissionObj(booking?.formSubmission);
          setBooking(data?.data?.data?.booking);
          setSchedule(data?.data?.data?.schedule);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
      setBookingId(initialBookingId);
      setStep(2);
    }
  }, [initialBookingId, isVerifyPending, scheduleId]);

  useEffect(() => {
    if (scheduleId && initialBookingId) {
      const config = {
        method: 'get',
        url: `${Config.url || ''}/api/v1/public/get_booking/${scheduleId}/${bookingId}`,
      };
      axios
        .request(config)
        .then(({ data }) => {
          setBooking(data.data?.booking);
        })
        .catch((err) => {
          console.log(err.response.data.error);
        });
    }
  }, [initialBookingId]);

  useEffect(() => {
    if (username) {
      axios
        .get(`${Config.url || ''}/api/v1/public/get_bookings_n_schedule/${workspaceID}/${username}`)
        .then((data) => {
          if (!data.data.success) {
            toast.error('Page not found.', {
              position: 'top-center',
            });
            navigate('/');
            return;
          }
          const schedule = data?.data?.data?.schedule;
          const bookings = data?.data?.data?.bookings;
          const form = data?.data?.data?.form;
          const timeSlot = Number(schedule?.timeSlot?.split(' ')[0]);
          setSlot(timeSlot);
          setDaysOfWeek(schedule?.availableWeekdays);
          setFields(form?.fields);
          setBookings(bookings);

          setScheduleId(schedule?._id);
          setFormId(form?._id);
        })
        .catch((err) => {
          console.log(err.response.data);
          toast.error('Page not found.', {
            position: 'top-center',
          });
          navigate('/');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [username]);

  const handleContinueFirstStep = () => {
    if (!selectedTime) {
      toast.error('Please select a time.', {
        position: 'top-center',
      });
      return;
    }
    const formData = new FormData();
    formData.append('scheduleId', scheduleId);
    formData.append('date', formatDateString(date));
    formData.append('time', convertTo24Hour(selectedTime));
    formData.append('timeZone', selectedTimezone.split(' ')[0]);
    formData.append('workspaceID', workspaceID);

    const config = {
      method: 'post',
      url: `${Config.url || ''}/api/v1/public/book_schedule`,
      data: formData,
    };

    setIsSubmitLoading(true);
    axios
      .request(config)
      .then((data) => {
        // console.log(data?.data?.message);
        const bookingId = data?.data?.data?._id;
        setBookingId(bookingId);
        setSearchParams({
          bookingId,
          isVerifyPending: true,
        });

        toast.success(data?.data?.message, {
          position: 'top-center',
        });

        setStep(1);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error, {
          position: 'top-center',
        });
        // console.log(err.response.data.error);
      })
      .finally(() => {
        setIsSubmitLoading(false);
      });
  };

  return (
    <>
      <div>
        <div className="bg-white rounded-lg flex flex-col gap-6 justify-center items-center h-screen w-screen overflow-auto p-8">
          <div className="space-y-6">
            {step === 0 && (
              <Scheduler
                date={date}
                setDate={setDate}
                daysOfWeek={daysOfWeek}
                selectedTimezone={selectedTimezone}
                setSelectedTimezone={setSelectedTimezone}
                selectedTime={selectedTime}
                setSelectedTime={setSelectedTime}
                bookings={bookings}
                slot={slot}
              />
            )}
            {step === 0 && (
              <Button disabled={!selectedTime} onClick={handleContinueFirstStep}>
                {isSubmitLoading ? (
                  <span className="border-2 border-white border-r-transparent rounded-full h-4 w-4 animate-spin" />
                ) : (
                  'Continue'
                )}
              </Button>
            )}
          </div>
          <div className="space-y-6">
            <div className={step !== 1 ? 'hidden' : ''}>
              <UserForm
                setStep={setStep}
                step={step}
                selectedTimezone={selectedTimezone}
                selectedTime={selectedTime}
                scheduleId={scheduleId}
                setSelectedTimezone={setSelectedTimezone}
                date={date}
                bookingId={bookingId}
                setBookingId={setBookingId}
                slot={slot}
                booking={booking}
                schedule={schedule}
              />
            </div>
            <div className={step !== 2 ? 'hidden' : ''}>
              {fields?.length > 0 && (
                <FormSubmission
                  fields={fields}
                  setFields={setFields}
                  setStep={setStep}
                  scheduleId={scheduleId}
                  formId={formId}
                  bookingId={bookingId}
                  meetingId={meetingId}
                  setBookingId={setBookingId}
                  selectedTime={selectedTime}
                  date={date}
                  selectedTimezone={selectedTimezone}
                  isFormAdded={isFormAdded}
                  formSubmissionObj={formSubmissionObj}
                  username={username}
                  booking={booking}
                  schedule={schedule}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {loading && (
        <div className="fixed top-0 left-0 w-screen h-screen bg-white flex items-center justify-center">
          {/* <span className="visually-hidden">Loading...</span> */}
          <span className="border-4 border-gray-400 rounded-full h-12 w-12 animate-spin border-t-transparent" />
        </div>
      )}
    </>
  );
}

export default GetSchedule;
