/* eslint-disable import/no-unresolved */
/* eslint-disable object-curly-newline */
/* eslint-disable no-trailing-spaces */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGlobal } from 'reactn';
import { toast } from 'sonner';
import addWorkspaceMember from '../../../actions/addWorkspaceMember';
import getUserSuggestion from '../../../actions/getUserSuggestion';
import { Button } from '../../../components/ui/button';
import { Dialog, DialogContent, DialogDescription, DialogTitle, DialogTrigger } from '../../../components/ui/dialog';
import { cn, validateEmail } from '../../../lib/utils';

function AddTeamMemberButton({ className, setRefresh = () => {} }) {
  const user = useGlobal('user')[0];
  const workspaceId = user?.workspace?._id;
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [userSuggestion, setUserSuggestion] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await getUserSuggestion(email);
        setUserSuggestion(data.data);
      } catch (err) {
        console.log(err);
        setUserSuggestion([]);
      }
    };
    if (email) {
      getData();
    } else {
      setUserSuggestion([]);
    }
  }, [email]);

  const handleAddTeamMember = async () => {
    try {
      setIsLoading(true);
      if (email) {
        const { data: res } = await addWorkspaceMember(workspaceId, email);
        console.log(res);
        if (res.success) {
          setName('');
          setEmail('');
          setIsOpen(false);
          setRefresh((prev) => !prev);
          toast.success('Member Invited Successfully!', {
            position: 'top-center',
          });
        } else {
          toast.error('Something went wrong!', {
            position: 'top-center',
          });
        }
      } else {
        alert('Please enter a valid email');
      }
    } catch (err) {
      setName('');
      setEmail('');
      setIsOpen(false);
      toast.error(err.response.data.error, {
        position: 'top-center',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger>
        <Button className={cn('flex items-center gap-2 hover:bg-[#193601] bg-[#173300] text-[#FFED74]', className)}>
          <span className="text-base font-semibold leading-normal">Add Team Member</span>
        </Button>
      </DialogTrigger>
      <DialogContent className="min-w-max" closeButtonClassName="top-2 right-2">
        <DialogTitle>Add Team Member</DialogTitle>
        <DialogDescription className="hidden" />

        {user?.workspace?.seats === user?.workspace?.members?.length ? (
          <div className="flex items-center gap-2">
            <span className="text-slate-600 text-sm font-medium leading-normal">
              You have reached the maximum number of team members.
            </span>

            <div>
              <Button onClick={() => navigate('/settings/plan')}>
                Upgrade Plan
              </Button>
            </div>
          </div>
        ) : (
          <div className="space-y-4 flex-grow flex flex-col justify-center">
            <label htmlFor="name" className="space-y-1 block">
              <span className="text-slate-700 text-sm font-medium leading-tight">Full name</span>
              <input
                type="text"
                placeholder="Full name"
                name="name"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-full block py-2.5 px-4 outline-none bg-gray-50 rounded-md border border-slate-300 focus:ring-0 focus:outline-none"
              />
            </label>
            <label htmlFor="email" className="space-y-1 block relative">
              <span className="text-slate-700 text-sm font-medium leading-tight flex items-center gap-[6px]">
                Email
              </span>
              <input
                type="email"
                placeholder="user@example.com"
                name="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="off"
                className="w-full block py-2.5 px-4 outline-none bg-gray-50 rounded-md border border-slate-300 focus:ring-0 focus:outline-none"
              />
              {userSuggestion.length > 0 && !validateEmail(email) && (
                <div className="absolute w-full bg-white border top-[95%] left-0 rounded py-2">
                  {userSuggestion.map((user) => (
                    <button
                      key={user._id}
                      className="py-1.5 px-3 text-sm hover:bg-gray-50 cursor-pointer w-full flex text-left truncate"
                      onClick={() => {
                        setEmail(user.contact_user.email);
                        setName(`${user.contact_user.firstName} ${user.contact_user.lastName}`);
                      }}
                    >
                      {user.contact_user.email}
                    </button>
                  ))}
                </div>
              )}
            </label>
            <div>
              <Button onClick={handleAddTeamMember}>
                {isLoading ? (
                  <span className="border-2 border-white border-r-transparent rounded-full h-4 w-4 animate-spin" />
                ) : (
                  'Add to Team'
                )}
              </Button>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default AddTeamMemberButton;
