import { cn } from '../../lib/utils';
import { Avatar, AvatarFallback, AvatarImage } from './avatar';

function AvatarGroup({
  avatars, max = 3, borderColor, avatarClassName, className, fallbackClassName,
}) {
  const displayAvatars = avatars.slice(0, max);
  const remaining = Math.max(avatars.length - max, 0);

  return (
    <div className={cn('flex -space-x-6', className)}>
      {displayAvatars.map((avatar, index) => (
        <Avatar size="sm" key={index} className={`border-2 ${avatarClassName}`} style={{ borderColor }}>
          <AvatarImage src={avatar.src} alt={avatar.alt} />
          <AvatarFallback className={fallbackClassName}>{avatar.fallback}</AvatarFallback>
        </Avatar>
      ))}
      {remaining > 0 && (
        <Avatar size="sm" className={`border-2 ${avatarClassName}`} style={{ borderColor }}>
          <AvatarFallback className={fallbackClassName}>
            +
            {remaining}
          </AvatarFallback>
        </Avatar>
      )}
    </div>
  );
}

export default AvatarGroup;
