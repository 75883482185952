import axios from 'axios';
import Config from '../config';

const getUserSuggestion = (email) => {
  return axios({
    method: 'get',
    url: `${Config.url || ''}/api/v1/user/auto_suggest_contact_by_email?email=${email}`,
  });
};

export default getUserSuggestion;
