import InvoiceTableContainer from '../../components/invoice-table-container';
import Package from '../../components/package';
import PaymentMethod from '../../components/payment-methode';

function BillingPage() {
  return (
    <div className="space-y-8">
      <div>
        <h4 className="text-[#101828] text-lg font-semibold font-inter leading-7">Billing</h4>
        <p className="text-slate-600 text-base font-normal leading-normal m-0">
          Manage your billing and payment details.
        </p>
      </div>
      <div className="grid lg:grid-cols-2 grid-cols-1 gap-6">
        <div>
          <Package />
        </div>
        <div>
          <PaymentMethod />
        </div>
      </div>
      <InvoiceTableContainer />
    </div>
  );
}

export default BillingPage;
