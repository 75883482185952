/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import { REGEXP_ONLY_DIGITS } from 'input-otp';
import { useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { toast } from 'sonner';
import { Button } from '../../../../../components/ui/button';
import { Dialog, DialogContent } from '../../../../../components/ui/dialog';
import { InputOTP, InputOTPGroup, InputOTPSlot } from '../../../../../components/ui/input-otp';
import Config from '../../../../../config';
import MeetingScheduleSuccessModal from '../../../components/meeting-schedule-success-modal';
import MeetingInfo from './meeting-info';

const validateEmail = (email) => {
  const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return pattern.test(email);
};

function UserForm({
  setStep,
  step,
  setSelectedTimezone,
  scheduleId,
  selectedTime,
  selectedTimezone,
  date,
  bookingId,
  slot,
  booking,
}) {
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [isOTPSent, setIsOTPSent] = useState(false);
  const [OTP, setOTP] = useState('');
  const [isVerified, setIsVerified] = useState(false);
  const [shouldContinue, setShouldContinue] = useState(false);
  const [shouldGotoNextStep, setShouldGotoNextStep] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  // Guest
  const [guest, setGuest] = useState(null);
  const [guests, setGuests] = useState([]);
  const [showAddGuestModal, setShowAddGuestModal] = useState(false);

  // Loading States
  const [isLoadingVerifyEmail, setIsLoadingVerifyEmail] = useState(false);
  const [isLoadingVerifyOTP, setIsLoadingVerifyOTP] = useState(false);
  const [isLoadingAddGuest, setIsLoadingAddGuest] = useState(false);

  // Success Modal
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleVerifyEmail = () => {
    if (!validateEmail(email)) {
      toast.error('Invalid Email Address', {
        position: 'top-center',
      });
      return;
    }

    if (name.length === 0) {
      toast.error('Please enter your name', {
        position: 'top-center',
      });
      return;
    }

    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('scheduleId', scheduleId);
    formData.append('bookingId', bookingId);

    const config = {
      method: 'post',
      url: `${Config.url || ''}/api/v1/public/send_guest_email`,
      data: formData,
    };
    setIsLoadingVerifyEmail(true);
    axios
      .request(config)
      .then((data) => {
        // console.log(data.data);
        setGuest(data.data.data);
        if (!data?.data?.data?.emailVerified) {
          toast.error('Email not verified yet', {
            description: 'Please check your email and verify.',
            position: 'top-center',
          });
          setIsOTPSent(true);
        } else {
          toast.success(data?.data?.message, {
            position: 'top-center',
          });
          setIsOTPSent(false);
          setIsVerified(true);
          setShouldContinue(true);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error, {
          position: 'top-center',
        });
        // console.log(err.response.data.error);
      })
      .finally(() => {
        setIsLoadingVerifyEmail(false);
      });
  };
  const handleVerifyOTP = () => {
    if (OTP.length !== 6) {
      toast.error('Invalid OTP', {
        position: 'top-center',
      });
      return;
    }

    const formData = new FormData();
    formData.append('scheduleId', scheduleId);
    formData.append('bookingId', bookingId);
    formData.append('guestId', guest._id);
    formData.append('emailCode', OTP);

    const config = {
      method: 'post',
      url: `${Config.url || ''}/api/v1/public/verify_guest`,
      data: formData,
    };

    setIsLoadingVerifyOTP(true);
    axios
      .request(config)
      .then((data) => {
        if (!data?.data?.data?.emailVerified) {
          toast.error('Email verification failed', {
            description: 'Please check your email and try again.',
            position: 'top-center',
          });
          setIsOTPSent(true);
          return;
        }

        toast.success('Email verified', {
          position: 'top-center',
        });
        setIsOTPSent(false);
        setIsVerified(true);
        setShouldContinue(true);

        // Remove isVerifyPending query param
        const url = new URL(window.location.href);
        url.searchParams.delete('isVerifyPending');

        window.history.replaceState({}, '', url);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error, {
          position: 'top-center',
        });
      })
      .finally(() => {
        setIsLoadingVerifyOTP(false);
      });
  };

  const handleBookSchedule = () => {
    if (!isVerified) {
      toast.error('Please verify your email first', {
        position: 'top-center',
      });
      return;
    }

    const formData = new FormData();
    formData.append('scheduleId', scheduleId);
    formData.append('bookingId', bookingId);
    formData.append('emailCode', OTP);
    const config = {
      method: 'post',
      url: `${Config.url || ''}/api/v1/public/save_guests`,
      data: formData,
    };

    axios
      .request(config)
      .then((data) => {
        if (booking?.preMeetingFormRequired === true) {
          setShowSuccessModal(true);
          setShouldContinue(false);
          setShouldGotoNextStep(true);
        } else {
          setSearchParams({
            bookingId,
          });
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error, {
          position: 'top-center',
        });
      })
      .finally(() => {
        setIsLoadingVerifyEmail(false);
      });
  };

  const handleAddGuest = (e) => {
    e.preventDefault();

    const name = e.target.name.value;
    const email = e.target.email.value;

    let flag = false;

    // Check if the email is already exist on guest list.
    guests.forEach((guest) => {
      if (guest.email === email) {
        setShowAddGuestModal(false);
        toast.error('Email already exist on guest list', {
          position: 'top-center',
        });
        flag = true;
      }
    });
    if (flag) return;

    // setGuests([...guests, { email, name }]);
    // setShowAddGuestModal(false);

    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('scheduleId', scheduleId);
    formData.append('bookingId', bookingId);

    const config = {
      method: 'post',
      url: `${Config.url || ''}/api/v1/public/send_guest_email`,
      data: formData,
    };
    setIsLoadingAddGuest(true);
    axios
      .request(config)
      .then((data) => {
        setGuests([
          ...guests,
          {
            email,
            name,
          },
        ]);
        setShowAddGuestModal(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error, {
          position: 'top-center',
        });
        // console.log(err.response.data.error);
      })
      .finally(() => {
        setIsLoadingAddGuest(false);
      });
  };

  return (
    <>
      <div className="space-y-6">
        <div className="flex lg:flex-row flex-col gap-6 p-6 bg-[#EEEEEE] rounded-xl 2xl:max-w-[633px]">
          <MeetingInfo setSelectedTimezone={setSelectedTimezone} />
          <hr className="border-gray-500 lg:hidden" />
          <div className="space-y-4 flex-grow flex flex-col justify-center">
            <label htmlFor="name" className="space-y-1 block">
              <span className="text-slate-700 text-sm font-medium leading-tight">Full name</span>
              <input
                type="text"
                placeholder="Full name"
                name="name"
                id="name"
                value={name}
                readOnly={isVerified}
                onChange={(e) => setName(e.target.value)}
                className="w-[320px] block py-2.5 px-4 outline-none bg-gray-50 rounded-md border border-slate-300 focus:ring-0 focus:outline-none"
              />
            </label>
            <label htmlFor="email" className="space-y-1 block">
              <span className="text-slate-700 text-sm font-medium leading-tight flex items-center gap-[6px]">
                Email
                {isVerified && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-5 text-[#09914F]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                )}
              </span>
              <input
                type="email"
                placeholder="you@company.com"
                name="email"
                id="email"
                readOnly={isVerified}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-[320px] block py-2.5 px-4 outline-none bg-gray-50 rounded-md border border-slate-300 focus:ring-0 focus:outline-none"
              />
              {email && !validateEmail(email) && (
                <small className="text-red-500 text-xs ml-2">Invalid Email Address</small>
              )}
            </label>
            {email && validateEmail(email) && !isOTPSent && !isVerified && (
              <div>
                <Button onClick={handleVerifyEmail}>
                  {isLoadingVerifyEmail ? (
                    <span className="border-2 border-white border-r-transparent rounded-full h-4 w-4 animate-spin" />
                  ) : (
                    'Verify Email'
                  )}
                </Button>
              </div>
            )}

            {email && validateEmail(email) && isOTPSent && !isVerified && (
              <div className="space-y-4">
                <label htmlFor="otp" className="space-y-1 block">
                  <span className="text-slate-700 text-sm font-medium leading-tight">We sent a code to your mail</span>
                  <InputOTP
                    id="otp"
                    value={OTP}
                    onChange={(val) => setOTP(val)}
                    className="border border-gray-500"
                    maxLength={6}
                    pattern={REGEXP_ONLY_DIGITS}
                  >
                    <InputOTPGroup>
                      <InputOTPSlot className="border border-r-0 bg-white border-gray-300" index={0} />
                      <InputOTPSlot className="border border-r-0 bg-white border-gray-300" index={1} />
                      <InputOTPSlot className="border border-r-0 bg-white border-gray-300" index={2} />
                      <InputOTPSlot className="border border-r-0 bg-white border-gray-300" index={3} />
                      <InputOTPSlot className="border border-r-0 bg-white border-gray-300" index={4} />
                      <InputOTPSlot className="border bg-white border-gray-300" index={5} />
                    </InputOTPGroup>
                  </InputOTP>
                </label>
                <Button onClick={handleVerifyOTP}>
                  {isLoadingVerifyOTP ? (
                    <span className="border-2 border-white border-r-transparent rounded-full h-4 w-4 animate-spin" />
                  ) : (
                    'Verify'
                  )}
                </Button>
              </div>
            )}
            {isVerified && (
              <div className="space-y-4">
                {guests.map((guest, index) => (
                  <div key={index} className="relative">
                    <input
                      type="email"
                      value={guest.email}
                      readOnly
                      className="w-[320px] block py-2.5 px-4 outline-none bg-gray-50 rounded-md border border-slate-300 focus:ring-0 focus:outline-none"
                    />
                  </div>
                ))}
                <Button
                  onClick={() => {
                    setShowAddGuestModal(true);
                  }}
                  className="bg-white hover:bg-gray-50 text-[#323333] border border-[#878888] text-sm font-semibold leading-tight"
                >
                  Add Guest
                </Button>
              </div>
            )}
          </div>
        </div>
        <div className="flex gap-3">
          <Button onClick={() => setStep(step - 1)}>Back</Button>
          <Button onClick={handleBookSchedule} disabled={!shouldContinue && !shouldGotoNextStep}>
            {isSubmitLoading ? (
              <span className="border-2 border-white border-r-transparent rounded-full h-4 w-4 animate-spin" />
            ) : (
              'Finish Scheduling'
            )}
          </Button>

          {shouldGotoNextStep && !showSuccessModal && (
            <Button
              onClick={() => {
                setStep(step + 1);
                setShowSuccessModal(false);
              }}
            >
              Continue
            </Button>
          )}
        </div>
      </div>
      <MeetingScheduleSuccessModal
        open={showSuccessModal}
        setOpen={setShowSuccessModal}
        date={date?.toDateString()}
        time={selectedTime}
        timeZone={selectedTimezone}
        bookingId={bookingId}
        scheduleId={scheduleId}
        url="/"
        slot={slot}
        email={email}
      >
        {/*  */}
        <div className="bg-white p-3 py-4 rounded-2xl w-full flex flex-col items-center justify-center space-y-6">
          <p className="text-gray-900 text-base font-medium leading-normal text-center">
            Let&apos;s talk more about your project to make the meeting more effective!
          </p>
          <Button
            onClick={() => {
              setStep(step + 1);
              setShowSuccessModal(false);
            }}
            className="px-[24px] py-[16px] flex h-auto text-white text-lg font-semibold leading-7"
          >
            Continue
          </Button>
        </div>
      </MeetingScheduleSuccessModal>

      <Dialog open={showAddGuestModal} onOpenChange={setShowAddGuestModal} className="">
        <DialogContent className="bg-[#EAECF0] max-w-[400px] py-8 px-6">
          <form onSubmit={handleAddGuest} className="space-y-4 flex-grow flex flex-col justify-center">
            <label htmlFor="name" className="space-y-1 block">
              <span className="text-slate-700 text-sm font-medium leading-tight">Full name</span>
              <input
                type="text"
                placeholder="Full name"
                name="name"
                id="name"
                className="w-full block py-2.5 px-4 outline-none bg-gray-50 rounded-md border border-slate-300 focus:ring-0 focus:outline-none"
              />
            </label>
            <label htmlFor="email" className="space-y-1 block">
              <span className="text-slate-700 text-sm font-medium leading-tight flex items-center gap-[6px]">
                Email
              </span>
              <input
                type="email"
                placeholder="guest@company.com"
                name="email"
                id="email"
                className="w-full block py-2.5 px-4 outline-none bg-gray-50 rounded-md border border-slate-300 focus:ring-0 focus:outline-none"
              />
            </label>
            <Button type="submit" className="bg-black">
              {isLoadingAddGuest ? (
                <span className="border-2 border-white border-r-transparent rounded-full h-4 w-4 animate-spin" />
              ) : (
                'Add Guest'
              )}
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default UserForm;
