/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import { useGlobal } from 'reactn';
import config from '../../../config';
import { getGravatarUrl } from '../../../lib/utils';

export default function MiniScreen({ audio }) {
    const [user] = useGlobal('user');
    return (
        <div className="bg-gray-300 bg-opacity-80 w-[180px] h-[150px]  flex-grow bg-center bg-cover bg-no-repeat rounded-xl flex flex-col justify-end relative overflow-hidden shadow shadow-gray-400 shadow-xl">
            {
                user?.picture ? <img src={`${config.url || ''}/${user.picture.location}`} alt="Picture" className="h-full w-full" /> : <img src={getGravatarUrl(`${user?.firstName} ${user?.lastName}`, user?.email, 300)} alt="Picture" className="h-full w-full" />
            }
            <div className="w-full h-full absolute top-2 left-0 p-4 flex items-end justify-end">
                <div className="p-1 px-2 bg-white rounded-lg justify-center items-center gap-1.5 inline-flex">
                    {/* <div className="w-6 h-6 relative" /> */}
                    {
                        audio && (
                            <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="microphone-02">
                                    <path id="Icon" d="M20 12V13C20 17.4183 16.4183 21 12 21C7.58172 21 4 17.4183 4 13V12M12 17C9.79086 17 8 15.2091 8 13V7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7V13C16 15.2091 14.2091 17 12 17Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </g>
                            </svg>
                        )
                    }

                    <div className="text-gray-900 text-xs font-semibold leading-normal">
                        {user?.firstName}
                        {' '}
                        {user?.lastName}
                    </div>
                </div>
            </div>
        </div>
    );
}
