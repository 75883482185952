import axios from 'axios';
import Config from '../config';

const getUserInfo = () => {
  return axios({
    method: 'get',
    url: `${Config.url || ''}/api/v1/user/me`,
  });
};

export default getUserInfo;
