/* eslint-disable camelcase */
/* eslint-disable indent */
import axios from 'axios';
import Config from '../config';

const getMeetingsByUserEmail = (user_email) => {
    return axios({
        method: 'GET',
        url: `${Config.url || ''}/api/v1/user/meetings_by_user_email?user_email=${user_email}`,
        data: {},
    });
};

export default getMeetingsByUserEmail;
