import axios from 'axios';
import Config from '../config';

const removeWorkspaceMember = (memberId) => {
  return axios({
    method: 'post',
    url: `${Config.url || ''}/api/v1/user/workspace/remove_workspace_member/${memberId}`,
  });
};

export default removeWorkspaceMember;
