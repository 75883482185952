import axios from 'axios';
import Config from '../config';

const sendInvitaion = (Id, data) => {
  return axios({
    method: 'post',
    url: `${Config.url || ''}/api/v1/user/meeting_send_invitation/${Id}`,
    data,
  });
};

export default sendInvitaion;
