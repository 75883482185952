/* eslint-disable object-curly-newline */
/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable indent */

import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useGlobal } from 'reactn';
import { toast } from 'sonner';
import { useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import getNotificationCount from '../../../actions/getNotificationCount';
import getNotifications from '../../../actions/getNotifications';
import markAllAsRead from '../../../actions/markAllAsRead';
import markAsRead from '../../../actions/markAsRead';
import { Button } from '../../../components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '../../../components/ui/dropdown-menu';
import BookingCancelledStatusModal from './booking-cancelled-status-modal';
import MeetingScheduleSuccessModalUser from './meeting-schedule-success-modal-user';
import notificationSound from '../../../assets/notification.wav';

function getHourMinuteDifference(dateString1, dateString2) {
  const date1 = new Date(dateString1);
  const date2 = new Date(dateString2);
  const diffMilliseconds = Math.abs(date2 - date1);
  const days = Math.floor(diffMilliseconds / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diffMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((diffMilliseconds % (1000 * 60 * 60)) / (1000 * 60));

  if (days > 0) {
    return `${days}d`;
  }
  if (hours > 0) {
    return `${hours}h`;
  }
  return `${minutes}m`;
}

export default function NotificationButton() {
  const user = useGlobal('user')[0];

  const [notificationCount, setNotificationCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [refreshNotifications, setRefreshNotifications] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const io = useSelector((state) => state.io.io);
  const { addToast } = useToasts();

  const [bookingData, setBookingData] = useState(null);

  const getNotificationData = async () => {
    try {
      const { data: notificationsCount } = await getNotificationCount();
      const { data: notificationList } = await getNotifications();
      setNotificationCount(notificationsCount?.data || 0);
      setNotifications(notificationList?.data || []);
      return notificationList?.data?.[notificationList.data.length - 1];
    } catch (error) {
      console.error('Failed to fetch notifications:', error);
    }
  };

  useEffect(() => {
    getNotificationData();
  }, [refreshNotifications]);

  useEffect(() => {
    if (io) {
      io.on('notification-in', async (data) => {
        if (data.notification && data.recipient?.toString() === user._id?.toString()) {
          const lastNotification = await getNotificationData();

          if (lastNotification?._id) {
            addToast(lastNotification.message, { appearance: 'info', autoDismiss: true });
          }
          const audio = document.createElement('audio');
          audio.style.display = 'none';
          audio.src = notificationSound;
          audio.autoplay = true;
          audio.onended = () => audio.remove();
          document.body.appendChild(audio);
        }
        // console.log(data, 'generated_brief');
      });
    }
  }, [io]);

  const handleMarkAsRead = async (id) => {
    console.log(id);
    try {
      const { data } = await markAsRead(id);
      if (data.success) {
        console.log(`Notification ${id} marked as read at (${data.data.readAt})`);
        setRefreshNotifications((prevState) => !prevState);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleMarkAllAsRead = async () => {
    try {
      const { data } = await markAllAsRead();
      if (data.success) {
        toast.success('All notifications marked as read', {
          position: 'top-center',
        });
        setRefreshNotifications((prevState) => !prevState);
      }
    } catch (error) {
      console.log(error);
      toast.error('Failed to mark all notifications as read', {
        position: 'top-center',
      });
    }
  };

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <button className="relative">
            <img src="/image/icons/bell.svg" alt="" className="w-6 h-6" />
            {notificationCount > 0 && (
              <div className="absolute flex items-center justify-center h-4 min-w-4 rounded-full p-1 text-xs top-0 -right-2 bg-[#D92D20] text-white font-bold">
                {notificationCount}
              </div>
            )}
          </button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-[400px]">
          <DropdownMenuLabel className="flex items-center justify-between">
            <div className="flex items-center gap-1">
              <img src="/image/icons/bell.svg" alt="" className="size-4" />
              Notifications <span>({notificationCount})</span>
            </div>
            <Button onClick={handleMarkAllAsRead} variant="ghost" size="sm">
              Mark all as read
            </Button>
          </DropdownMenuLabel>
          <DropdownMenuSeparator />
          <div className="max-h-[600px] overflow-y-auto overflow-x-hidden">
            {notifications?.map((notification, i) => (
              <div key={notification._id}>
                {notification?.type === 'MEETING_ENDED' && (
                  <Link to={`/briefs/${notification?.data?.meeting?._id}`}>
                    <RenderNotification notification={notification} handleMarkAsRead={handleMarkAsRead} />
                  </Link>
                )}
                {notification?.type === 'BRIEF_GENERATION_FINISHED' && (
                  <Link to={`/briefs/${notification?.data?.meetingId}`}>
                    <RenderNotification notification={notification} handleMarkAsRead={handleMarkAsRead} />
                  </Link>
                )}
                {(notification?.type === 'BOOK_SCHEDULE' || notification?.type === 'BOOK_RESCHEDULE') && (
                  <RenderNotification
                    notification={notification}
                    handleMarkAsRead={handleMarkAsRead}
                    onSmash={() => {
                      setBookingData(notification?.data?.booking);
                      setShowSuccessModal(true);
                    }}
                  />
                )}
                {notification?.type === 'CANCEL_BOOKING' && (
                  <RenderNotification
                    notification={notification}
                    handleMarkAsRead={handleMarkAsRead}
                    onSmash={() => {
                      setBookingData(notification?.data?.booking);
                      setShowCancelModal(true);
                    }}
                  />
                )}
                {notification?.type !== 'BRIEF_GENERATION_FINISHED' &&
                  notification?.type !== 'BOOK_SCHEDULE' &&
                  notification?.type !== 'BOOK_RESCHEDULE' &&
                  notification?.type !== 'MEETING_ENDED' &&
                  notification?.type !== 'CANCEL_BOOKING' && (
                    <RenderNotification notification={notification} handleMarkAsRead={handleMarkAsRead} />
                  )}
                {i !== notifications.length - 1 && <DropdownMenuSeparator />}
              </div>
            ))}
          </div>
        </DropdownMenuContent>
      </DropdownMenu>
      <MeetingScheduleSuccessModalUser
        open={showSuccessModal}
        setOpen={setShowSuccessModal}
        date={moment(bookingData?.bookingDate).format('YYYY-MM-DD')}
        time={bookingData?.bookingTime}
        timeZone={bookingData?.bookingTimeZone}
        bookingId={bookingData?._id}
        scheduleId={bookingData?.schedule}
        userName={user.username}
      />
      <BookingCancelledStatusModal open={showCancelModal} setOpen={setShowCancelModal} data={bookingData} />
    </>
  );
}

function RenderNotification({ notification, handleMarkAsRead, onSmash = () => {}, i }) {
  return (
    <DropdownMenuItem onClick={onSmash} className="flex items-center gap-2">
      {/* <div>
        <div className="p-2 rounded-full size-16 bg-gray-100 border flex items-center justify-center">
          <img src="/image/icons/bell.svg" alt="" className="size-6 opacity-70" />
        </div>
      </div> */}
      <div>
        <h6 className="font-semibold text-sm">{notification?.title}</h6>
        <p className="text-gray-400 text-xs mb-1">{notification?.message}</p>
        <small className="text-gray-400">{getHourMinuteDifference(new Date(), notification?.createdAt)}</small>
      </div>
      <div className="min-w-max ml-auto">
        {notification?.readAt === 'false' && (
          <button title="Mark as read" onClick={() => handleMarkAsRead(notification?._id)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
              />
            </svg>
          </button>
        )}
      </div>
    </DropdownMenuItem>
  );
}
