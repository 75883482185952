/* eslint-disable object-curly-newline */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { Button } from '../../../../../components/ui/button';
import { validateEmail } from '../../../../../lib/utils';
import MeetingInfo from './meeting-info';

function GuestUserForm({
  setSelectedTimezone,
  handleBookSchedule,
  name,
  setName,
  email,
  setEmail,
  guests,
  setGuests,
  isSubmitLoading,
  userSuggestion,
  slot,
}) {
  const [showAddGuest, setShowAddGuest] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const { addToast } = useToasts();

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ',') {
      const email = inputValue.trim();
      if (email && !guests.find((guest) => guest.email === email) && validateEmail(email)) {
        setGuests([...guests, { email, name: 'Guest User' }]);
      } else {
        addToast('Invalid email address', {
          appearance: 'error',
          autoDismiss: true,
        });
      }
      setInputValue('');
      e.preventDefault();
    } else if (e.key === 'Backspace' && inputValue === '') {
      setGuests(guests.slice(0, -1));
    }
  };

  const handleRemoveEmail = (emailToRemove) => {
    setGuests(guests.filter((guest) => guest.email !== emailToRemove));
  };

  const handleContinue = () => {
    if (inputValue) {
      const email = inputValue.trim();
      if (email && !guests.find((guest) => guest.email === email) && validateEmail(email)) {
        setGuests([...guests, { email, name: 'Guest User' }]);
        handleBookSchedule();
      } else {
        addToast('Invalid guest email address', {
          appearance: 'error',
          autoDismiss: true,
        });
      }
      setInputValue('');
    } else {
      handleBookSchedule();
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex lg:flex-row flex-col gap-6 p-6 bg-[#EEEEEE] rounded-xl 2xl:max-w-[633px]">
        <MeetingInfo setSelectedTimezone={setSelectedTimezone} slot={slot} />
        <hr className="border-gray-500 lg:hidden" />
        <div className="space-y-4 flex-grow flex flex-col justify-center">
          {/* <label htmlFor="name" className="space-y-1 block">
            <span className="text-slate-700 text-sm font-medium leading-tight">Full name</span>
            <input
              type="text"
              placeholder="Full name"
              name="name"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-[320px] block py-2.5 px-4 outline-none bg-gray-50 rounded-md border border-slate-300 focus:ring-0 focus:outline-none"
            />
          </label> */}
          <label htmlFor="email" className="space-y-1 block relative">
            <span className="text-slate-700 text-sm font-medium leading-tight flex items-center gap-[6px]">Email</span>
            <input
              type="email"
              placeholder="you@company.com"
              name="email"
              id="email"
              value={email}
              autoComplete="off"
              onChange={(e) => setEmail(e.target.value)}
              className="w-[320px] block py-2.5 px-4 outline-none bg-gray-50 rounded-md border border-slate-300 focus:ring-0 focus:outline-none"
            />
            {email && !validateEmail(email) && (
              <small className="text-red-500 text-xs ml-2">Invalid Email Address</small>
            )}

            {
              userSuggestion.length > 0 && !validateEmail(email) && (
                <div className="absolute w-full bg-white border top-[70%] left-0 rounded py-2">
                  {userSuggestion.map((user) => (
                    <button
                      key={user._id}
                      className="py-1.5 px-3 text-sm hover:bg-gray-50 cursor-pointer w-full flex text-left truncate"
                      onClick={() => {
                        setEmail(user.contact_user.email);
                        setName(`${user.contact_user.firstName} ${user.contact_user.lastName}`);
                      }}
                    >
                      {user.contact_user.email}
                    </button>
                  ))}
                </div>
              )
            }
          </label>
          <div className="space-y-4">
            {
              !showAddGuest ? (
                <Button
                  onClick={() => setShowAddGuest(true)}
                  className="bg-white hover:bg-gray-50 text-[#323333] border border-[#878888] text-sm font-semibold leading-tight"
                >
                  Add Guest
                </Button>
              ) : (
                <div className="w-[320px]">
                  <label htmlFor="guestsEmail" className="text-slate-700 text-sm font-medium leading-tight flex items-center gap-[6px] mb-1">Guest Email(s)</label>
                  <div className="flex flex-wrap items-start p-2 border border-gray-300 rounded-md bg-white min-h-[100px] overflow-auto">
                    {guests.map((guest, index) => (
                      <div
                        key={index}
                        className="flex border items-center bg-gray-100 text-gray-700 px-1.5 pt-0.5 pb-1 mr-2 mb-2 rounded"
                      >
                        <span className="leading-none">{guest.email}</span>
                        <span
                          className="ml-2 text-lg cursor-pointer leading-none"
                          onClick={() => handleRemoveEmail(guest.email)}
                        >
                          &times;
                        </span>
                      </div>
                    ))}
                    <input
                      type="text"
                      value={inputValue}
                      placeholder="Enter email addresses"
                      className="flex-grow p-1 border-none outline-none focus:ring-0 text-sm bg-transparent"
                      onKeyDown={handleKeyDown}
                      id="guestsEmail"
                      onChange={(e) => setInputValue(e.target.value)}
                    />
                  </div>
                </div>
              )
            }

            {/* <div className="space-y-3">
              {guests.length > 0 && <p className="text-sm">Guests</p>}
              {guests.map((guest, index) => (
                <div key={index} className="relative">
                  <input
                    type="email"
                    value={guest.email}
                    readOnly
                    className="w-[320px] block py-2.5 px-4 outline-none bg-gray-50 rounded-md border border-slate-300 focus:ring-0 focus:outline-none"
                  />
                </div>
              ))}
            </div> */}
          </div>
        </div>
      </div>
      <div className="flex gap-3">
        <Button onClick={handleContinue}>
          {isSubmitLoading ? (
            <span className="border-2 border-white border-r-transparent rounded-full h-4 w-4 animate-spin" />
          ) : (
            'Continue'
          )}
        </Button>
      </div>
    </div>
  );
}

export default GuestUserForm;
