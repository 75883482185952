import axios from 'axios';
import Config from '../config';

const getTransactions = (page, limit) => {
  return axios({
    method: 'get',
    url: `${Config.url || ''}/api/v1/user/transactions${page ? `?page=${page}` : ''}${limit ? `&limit=${limit}` : ''}`,
    data: {},
  });
};

export default getTransactions;
