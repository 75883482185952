/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import emojiRegex from 'emoji-regex';
import moment from 'moment';
import { FiDownloadCloud } from 'react-icons/fi';
import ReactImageAppear from 'react-image-appear';
import { useGlobal } from 'reactn';
import striptags from 'striptags';
import Config from '../../../config';
import './Message.sass';
import { getGravatarUrl } from '../../../lib/utils';

function Message({
  message, previous, next, onOpen,
}) {
  const { content, date } = message;
  let { author } = message;

  const user = useGlobal('user')[0];

  if (!author) author = { firstName: 'Deleted', lastName: 'User' };
  if (previous && !previous.author) previous.author = { firstName: 'Deleted', lastName: 'User' };
  if (next && !next.author) next.author = { firstName: 'Deleted', lastName: 'User' };

  const isMine = user?._id === author._id;

  let attachPrevious = false;
  let attachNext = false;

  if (
    previous
    && Math.abs(moment(previous.date).diff(moment(date), 'minutes')) < 3
    && author._id === previous.author._id
  ) attachPrevious = true;
  if (next && Math.abs(moment(next.date).diff(moment(date), 'minutes')) < 3 && author._id === next.author._id) attachNext = true;

  function Picture({ user }) {
    if (user.picture) return <img src={`${Config.url || ''}/${user?.picture?.location}`} alt="Picture" />;
    return (
      <img src={getGravatarUrl(`${user?.firstName} ${user?.lastName}`, user?.email, 300)} alt="Picture" />
    );
  }

  function Details({ side, isMine }) {
    if (!attachPrevious) {
      return (
        <div className="flex justify-between">
          {
            isMine ? <span className="message-details min-w-max">You</span> : <span className="message-details min-w-max">{`${author.firstName} ${author.lastName}`}</span>
          }
          <div className={`message-details ${side}`}>{moment(date).format('MMM DD - h:mm A')}</div>
        </div>
      );
    }
  }

  function PictureOrSpacer() {
    if (attachPrevious) return <div className="spacer" />;
    return (
      <div className="picture">
        <Picture user={author} />
      </div>
    );
  }

  const noEmoji = content.replace(emojiRegex(), '');
  const isOnlyEmoji = !noEmoji.replace(/[\s\n]/gm, '');

  const getBubble = () => {
    if (attachPrevious || isOnlyEmoji) {
      if (isMine) return ' right';
      return ' left';
    }
    if (isMine) return ' bubble-right right';
    return ' bubble-left left';
  };

  const convertUrls = (text) => {
    const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;
    return text.replace(urlRegex, (url) => {
      return `<a href="${url}" target="_blank">${url}</a>`;
    });
  };

  function Content() {
    switch (message.type) {
      case 'image': {
        const imageSrc = `${Config.url || ''}/api/images/${message.content}/512`;

        return (
          imageSrc ? (
            <ReactImageAppear
              src={imageSrc}
              animationDuration="0.2s"
              onClick={() => onOpen(message)}
            />
          ) : (
            <div>Error: Image not found</div> // Fallback in case image URL is invalid
          )
        );
      }
      case 'file': {
        const isImage = message.file && message.file.name.match(/\.(jpeg|jpg|gif|png)$/i);
        const fileUrl = `${Config.url || ''}/api/files/${message.content}`;

        return (
          <>
            {
              isImage && fileUrl
                ? (
                  <img src={fileUrl} alt="File" />
                ) : null
            }
            <a
              href={fileUrl}
              download={message.file ? message.file.name : 'File'}
              target="_blank"
              rel="noreferrer"
            >
              <div className="content-download">
                <div className="content-file">
                  <div className="content-name">{message.file ? message.file.name : 'File'}</div>
                  <div className="content-size">
                    {message.file ? `${Math.round((message.file.size / 1024 / 1024) * 10) / 10} MB` : 'Size'}
                  </div>
                </div>
                <div className="content-icon">
                  <FiDownloadCloud />
                </div>
              </div>
            </a>
          </>
        );
      }
      default:
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: convertUrls(striptags(content, ['a', 'strong', 'b', 'i', 'em', 'u', 'br'])),
            }}
          />
        );
    }
  }

  const getBubbleClass = () => {
    if (message.type === 'image') return 'bubble-image';
    return isOnlyEmoji ? 'emoji-bubble' : 'bubble';
  };

  return (
    <div
      className={`message ${isMine ? 'right' : 'left'}${attachPrevious ? ' attach-previous' : ''}${attachNext ? ' attach-next' : ''
        }`}
    >
      {
        !isMine && <PictureOrSpacer />
      }
      <div
        className={`content-x ${isMine ? 'right' : ''}${attachPrevious ? ' attach-previous' : ''}${attachNext ? ' attach-next' : ''
          }`}
      >
        <Details isMine={isMine} side={isMine ? 'right' : 'left'} />
        <div
          className={`${getBubbleClass()}${getBubble()}${attachPrevious ? ' attach-previous' : ''}${attachNext ? ' attach-next' : ''
            }`}
        >
          <Content />
        </div>
      </div>
    </div>
  );
}

export default Message;
