import md5 from 'md5';
import Config from '../../../config';
import { getHourMinuteDifference } from './meeting-card-item';

function ContactItem({ contact, setSelectedContact, selectedContact }) {
  const { contact_user: contactUser } = contact;
  const userEmail = contactUser?.email;
  const userName = `${contactUser?.firstName} ${contactUser?.lastName}`;
  const hash = md5(userEmail?.trim()?.toLowerCase() || 'default@email.com');
  const gravatarUrl = `https://www.gravatar.com/avatar/${hash}?s=80&d=https%3A%2F%2Fui-avatars.com%2Fapi%2F/${encodeURIComponent(userName)}/80/random`;

  return (
    <div onClick={() => setSelectedContact(contact)} className={`flex items-center gap-3 p-4 border-b hover:bg-gray-100 cursor-pointer ${selectedContact?._id === contact._id ? 'bg-gray-100' : ''}`}>
      <div className="w-[60px] h-[60px] rounded-full overflow-hidden flex items-center justify-center">
        <img
          className="w-full h-full object-cover"
          src={`${contactUser?.picture?.location
            ? `${Config.url || ''}/${contactUser?.picture?.location}`
            : gravatarUrl
          }`}
          alt="Profile"
        />
      </div>
      <div className="flex flex-col gap-2">
        <h4 className="text-gray-900 text-sm font-semibold leading-tight my-0">
          {contactUser?.firstName}
          {' '}
          {contactUser?.lastName}
        </h4>
        <div className="flex gap-2 text-sm text-slate-600">
          <p>
            {new Date(contactUser?.createdAt).toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: 'numeric' })}
          </p>

          <div>
            {contactUser?.lastOnline ? getHourMinuteDifference(new Date(), contactUser?.lastOnline || 0) === '0m' ? (
              <div className="flex items-center gap-1">
                <span className="relative flex h-1.5 w-1.5 rounded-full bg-green-500 animate-pulse" />
                <span>Online</span>
              </div>
            ) : ` - Active ${getHourMinuteDifference(new Date(), contactUser?.lastOnline || 0)} ago` : 'Not Online yet'}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactItem;
