/* eslint-disable object-curly-newline */
/* eslint-disable no-useless-escape */
/* eslint-disable operator-linebreak */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import getMeetingHistory from '../../../actions/getMeetingHistory';
import getMeetingsByUserEmail from '../../../actions/getMeetingsByUserEmail';
import { Button } from '../../../components/ui/button';
import { Checkbox } from '../../../components/ui/checkbox';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '../../../components/ui/dialog';
import Config from '../../../config';
import MeetingHistoryItem from './meeting-history-item';

function EditProjectSpace({ open, setOpen, resourceId, prevData, getResourceData }) {
  const { addToast } = useToasts();
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const [meetingHistory, setMeetingHistory] = useState([]);

  const [selectedMeetings, setSelectedMeetings] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await getMeetingHistory();
        setMeetingHistory(data?.data);
      } catch (e) {
        console.error(e);
      }
    };

    getData();
  }, []);

  useEffect(() => {
    if (prevData) {
      setName(prevData?.name);
    }
  }, [prevData]);

  const handleUpdateSpace = async () => {
    if (!name) return;

    setIsSubmitLoading(true);

    try {
      const res = await axios.post(`${Config.url || ''}/api/v1/user/edit_resource/${resourceId}`, {
        name,
        user_email: email,
        meetingIds: selectedMeetings,
      });
      addToast(res.data.message, { appearance: 'success' });
      setName('');
      setEmail('');
      setOpen(false);
      if (getResourceData) {
        getResourceData();
      }
    } catch (err) {
      if (err?.response?.data?.message) {
        addToast(err?.response?.data?.message, { appearance: 'error' });
      } else {
        addToast(err?.response?.data?.error || 'Something went wrong', { appearance: 'error' });
      }
    } finally {
      setIsSubmitLoading(false);
    }
  };

  const handleMeetingsByUserEmail = async (e) => {
    setEmail(e.target.value);

    if (!e.target.value) return;

    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(email).toLowerCase())) {
      return;
    }

    const { data } = await getMeetingsByUserEmail(e.target.value);

    setMeetingHistory(data?.data);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="min-w-max p-[32px 30px] pt-[12px]">
        <DialogHeader>
          <DialogTitle className="hidden">Edit Project Space</DialogTitle>
          <DialogDescription className="hidden" />
        </DialogHeader>
        <div className="px-3 py-[22px] bg-[#eeeeee] rounded-2xl">
          <div className="space-y-4 flex-grow flex flex-col justify-center">
            <label htmlFor="name" className="space-y-1 block">
              <span className="text-slate-700 text-sm font-medium leading-tight">Project Space Name</span>
              <input
                type="text"
                placeholder="Name"
                name="name"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-full block py-2.5 px-4 outline-none bg-gray-50 rounded-md border border-slate-300 focus:ring-0 focus:outline-none"
              />
            </label>
            <label htmlFor="email" className="space-y-1 block">
              <span className="text-slate-700 text-sm font-medium leading-tight flex items-center gap-[6px]">
                Clients Email
              </span>
              <input
                type="email"
                placeholder="client@company.com"
                name="email"
                id="email"
                value={email}
                onChange={(e) => handleMeetingsByUserEmail(e)}
                className="w-full block py-2.5 px-4 outline-none bg-gray-50 rounded-md border border-slate-300 focus:ring-0 focus:outline-none"
              />
            </label>

            {meetingHistory.length > 0 && (
              <div className="text-[#344054] text-sm font-medium font-['Inter'] leading-tight">
                Select Previous Meeting associated with this space
              </div>
            )}

            <div className="max-h-[260px] overflow-y-auto">
              <div className="space-y-3">
                {meetingHistory?.map((meeting) => (
                  <div className="flex gap-2 items-center" key={meeting?._id}>
                    <Checkbox
                      id={meeting?._id}
                      className="border-[#d0d5dd]"
                      checked={selectedMeetings.includes(meeting?._id)}
                      onCheckedChange={(val) => {
                        if (val) {
                          setSelectedMeetings([...selectedMeetings, meeting?._id]);
                        } else {
                          setSelectedMeetings(selectedMeetings.filter((id) => id !== meeting?._id));
                        }
                      }}
                    />

                    <label htmlFor={meeting?._id} className="w-full">
                      <MeetingHistoryItem data={meeting} maxAvatar={1} />
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div>
          <Button
            onClick={() => {
              handleUpdateSpace();
            }}
          >
            {isSubmitLoading ? (
              <span className="border-2 border-white border-r-transparent rounded-full h-4 w-4 animate-spin" />
            ) : (
              'Update'
            )}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default EditProjectSpace;
