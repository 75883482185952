/* eslint-disable react/jsx-indent */
/* eslint-disable object-curly-newline */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent-props */

import VideoDeviceList from './VideoDeviceList';

export default function VideoButton({ video, videoDeviceId, videoDevices, videoSelectListShow, setVideoSelectListShow, switchVideoDevice, getVideo, stopVideo, produceVideo }) {
    return (
        <div className="relative">
            {
                videoSelectListShow && (
                    <div className="absolute -top-10 left-0 transform -translate-x-1 -translate-y-6">
                        <VideoDeviceList videoDevices={videoDevices} videoDeviceId={videoDeviceId} switchVideoDevice={switchVideoDevice} />
                    </div>
                )
            }
            <div className="flex items justify-center items-center gap-2 bg-white bg-opacity-20 rounded-xl">
                <div className="pl-2 cursor-pointer" onClick={() => setVideoSelectListShow((prev) => !prev)}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="SVGRepo_bgCarrier" strokeWidth="0" />
                        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
                        <g id="SVGRepo_iconCarrier">
                            <path d="M18.2929 15.2893C18.6834 14.8988 18.6834 14.2656 18.2929 13.8751L13.4007 8.98766C12.6195 8.20726 11.3537 8.20757 10.5729 8.98835L5.68257 13.8787C5.29205 14.2692 5.29205 14.9024 5.68257 15.2929C6.0731 15.6835 6.70626 15.6835 7.09679 15.2929L11.2824 11.1073C11.673 10.7168 12.3061 10.7168 12.6966 11.1073L16.8787 15.2893C17.2692 15.6798 17.9024 15.6798 18.2929 15.2893Z" fill="#ffffff" />
                        </g>
                    </svg>
                </div>
                <div
                    onClick={() => (video ? stopVideo() : getVideo().then((stream) => produceVideo(stream)))}
                    className={`cursor-pointer w-12 h-12 p-3 rounded-xl justify-center items-center flex bg-white ${video ? '' : ' text-white bg-opacity-25'}`}
                >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="video-recorder">
                            <g id="Icon">
                                <path d="M22 8.93137C22 8.32555 22 8.02265 21.8802 7.88238C21.7763 7.76068 21.6203 7.69609 21.4608 7.70865C21.2769 7.72312 21.0627 7.93731 20.6343 8.36569L17 12L20.6343 15.6343C21.0627 16.0627 21.2769 16.2769 21.4608 16.2914C21.6203 16.3039 21.7763 16.2393 21.8802 16.1176C22 15.9774 22 15.6744 22 15.0686V8.93137Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M2 9.8C2 8.11984 2 7.27976 2.32698 6.63803C2.6146 6.07354 3.07354 5.6146 3.63803 5.32698C4.27976 5 5.11984 5 6.8 5H12.2C13.8802 5 14.7202 5 15.362 5.32698C15.9265 5.6146 16.3854 6.07354 16.673 6.63803C17 7.27976 17 8.11984 17 9.8V14.2C17 15.8802 17 16.7202 16.673 17.362C16.3854 17.9265 15.9265 18.3854 15.362 18.673C14.7202 19 13.8802 19 12.2 19H6.8C5.11984 19 4.27976 19 3.63803 18.673C3.07354 18.3854 2.6146 17.9265 2.32698 17.362C2 16.7202 2 15.8802 2 14.2V9.8Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </g>
                        </g>
                    </svg>
                </div>
            </div>
        </div>
    );
}
