/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
import { useGlobal } from 'reactn';
import { NavLink, Outlet } from 'react-router-dom';
import PageHeading from '../../components/page-heading';
import Layout from '../../layout';

const navigation = [
  {
    label: 'Workspace',
    href: './workspace',
  },
  {
    label: 'My details',
    href: './my-details',
  },
  {
    label: 'Team',
    href: './team',
  },
  {
    label: 'Plan',
    href: './plan',
    secure: true,
  },
  {
    label: 'Billing',
    href: './billing',
    secure: true,
  },
];

function SettingsPage() {
  const [user] = useGlobal('user');
  return (
    <Layout>
      <div className="space-y-6">
        <PageHeading title="Settings" />
        <ul className="flex pl-0">
          {navigation
            ?.filter((item) => {
              if (user?.workspace_member?.role === 'owner') {
                return true;
              }
              if (user?.workspace_member?.role !== 'owner' && item?.secure) {
                return false;
              }
              return true;
            })
            .map((item, i) => (
              <li key={i}>
                <NavLink
                  to={item?.href}
                  className={({ isActive }) =>
                    `px-4 py-[12px] hover:text-gray-900 ${
                      isActive ? 'text-gray-900 border-b-2 border-black' : 'text-slate-700  border-b'
                    }`
                  }
                >
                  {item.label}
                </NavLink>
              </li>
            ))}
        </ul>
        <div className="pt-4">
          <Outlet />
        </div>
      </div>
    </Layout>
  );
}

export default SettingsPage;
