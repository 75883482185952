/* eslint-disable no-unused-vars */
import 'emoji-mart/css/emoji-mart.css';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { FiPaperclip } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { useGlobal } from 'reactn';
import getRooms from '../../../actions/getRooms';
import message from '../../../actions/message';
import typing from '../../../actions/typing';
import uploadFile from '../../../actions/uploadFile';
import uploadImage from '../../../actions/uploadImage';
import Actions from '../../../constants/Actions';
import './BottomBar.sass';

function BottomBar({ disabled }) {
  const fileInput = useRef(null);

  const ref = useGlobal('ref')[0];
  const room = useSelector((state) => state.io.room);
  const user = useGlobal('user')[0];

  const [text, setText] = useState('');
  const [isPicker, showPicker] = useGlobal('isPicker');
  const [pictureRefs, addPictureRef] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (room) {
      if (text === '') dispatch(typing(room, false));
      else dispatch(typing(room, true));
    }
  }, [text]);

  const sendMessage = () => {
    if (text.length === 0) return;
    message({
      roomID: room._id,
      authorID: user?._id,
      content: text,
      contentType: 'text',
    }).then(() => {
      getRooms()
        .then((res) => dispatch({ type: Actions.SET_ROOMS, rooms: res.data.rooms }))
        .catch((err) => console.log(err));
    });
    const newMessage = {
      _id: Math.random(),
      author: { ...user, _id: user?._id },
      content: text,
      type: 'text',
      date: moment(),
    };
    dispatch({ type: Actions.MESSAGE, message: newMessage });
    setText('');
    showPicker(false);
  };

  const handleKeyPress = (event) => {
    showPicker(false);
    if (event.key === 'Enter') sendMessage();
  };

  const sendImages = async (images) => {
    const tmpRefs = [];
    for (let i = 0; i < images.length; i++) {
      const image = images[i];
      tmpRefs.push(ref + i);
      const res = await uploadImage(image, ref + i);
      message({
        roomID: room._id,
        authorID: user?._id,
        content: res.data.image.shieldedID,
        type: 'image',
      });
      const newMessage = {
        _id: Math.random(),
        author: { ...user, _id: user?._id },
        content: res.data.image.shieldedID,
        type: 'image',
        date: moment(),
      };
      dispatch({ type: Actions.MESSAGE, message: newMessage });
    }
    addPictureRef([...pictureRefs, ...tmpRefs]);
    showPicker(false);
    getRooms()
      .then((res) => dispatch({ type: Actions.SET_ROOMS, rooms: res.data.rooms }))
      .catch((err) => console.log(err));
  };

  const sendFiles = async (files) => {
    for (let i = 0; i < files.length; i++) {
      if (files[i].size / (1024 * 1024) > 10) return alert('File exceeds 10MB limit!');
    }
    const tmpRefs = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      tmpRefs.push(ref + i);
      const res = await uploadFile(file, ref + i);
      message({
        roomID: room._id,
        authorID: user?._id,
        content: res.data.file.shieldedID,
        type: 'file',
        fileID: res.data.file._id,
      });
      const newMessage = {
        _id: Math.random(),
        author: { ...user, _id: user?._id },
        content: res.data.file.shieldedID,
        type: 'file',
        date: moment(),
        file: res.data.file,
      };
      dispatch({ type: Actions.MESSAGE, message: newMessage });
    }
    addPictureRef([...pictureRefs, ...tmpRefs]);
    showPicker(false);
    getRooms()
      .then((res) => dispatch({ type: Actions.SET_ROOMS, rooms: res.data.rooms }))
      .catch((err) => console.log(err));
  };

  return (
    <div className="p-6 border-t space-y-3">
      {/*  */}
      <div className="h-[75px] px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-300">
        <textarea
          type="text"
          value={text}
          disabled={disabled}
          onChange={(e) => setText(e.target.value)}
          data-emoji-input="unicode"
          // onKeyPress={handleKeyPress}
          onKeyDown={handleKeyPress}
          onFocus={() => showPicker(false)}
          style={{ resize: 'none' }}
          className={`w-full focus:outline-none ${disabled ? 'cursor-not-allowed' : ''}`}
          placeholder="Message"
        />
      </div>

      <div className="flex gap-3">
        <input className="hidden" type="file" ref={fileInput} multiple onChange={(e) => sendFiles(e.target.files)} />
        <button onClick={() => fileInput && fileInput.current && fileInput.current.click()} className="w-11 h-11 p-3 bg-white rounded-lg shadow border border-gray-300 justify-center items-center flex">
          <FiPaperclip />
        </button>
        <button onClick={sendMessage} className="px-4 py-2.5 bg-black rounded-lg shadow border border-black text-white text-base font-semibold leading-normal">
          Send
        </button>
      </div>
    </div>
  );
}

export default BottomBar;
