import MeetingHistoryContainer from '../components/meeting-history-container';
import WelcomeHeading from '../components/welcome-heading';
import Layout from '../layout';

function Meetings() {
  return (
    <Layout>
      <div className="space-y-6 h-full flex flex-col">
        <WelcomeHeading />
        <MeetingHistoryContainer />
      </div>
    </Layout>
  );
}

export default Meetings;
