import { useEffect, useState } from 'react';
import config from '../../../config';

function DragAndDropInput({ callback, placeHolderFileUrl }) {
  const [file, setFile] = useState(null);
  const [error, setError] = useState('');
  const [preview, setPreview] = useState(null);

  const allowedTypes = ['image/png', 'image/jpeg', 'image/gif', 'image/svg+xml'];
  const maxSize = 1000;
  const minSize = 100;

  const handleFileValidation = (file) => {
    // Check file type
    if (!allowedTypes.includes(file?.type)) {
      setError('Only SVG, PNG, JPG, or GIF files are allowed.');
      return false;
    }

    // Validate image dimensions
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      if ((img.width > maxSize && img.width < minSize) || (img.height > maxSize && img.height < minSize)) {
        setError(`Image must be less than ${maxSize}x${maxSize}px and greater than ${minSize}x${minSize}px.`);
        return false;
      }

      if (img.width !== img.height) {
        setError('Image must have 1:1 aspect ratio.');
        return false;
      }

      setPreview(URL.createObjectURL(file));
      setFile(file);
      callback(file);
      setError('');
      return true;
    };

    return true;
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    handleFileValidation(droppedFile);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    handleFileValidation(selectedFile);
  };

  useEffect(() => {
    if (placeHolderFileUrl) {
      if (typeof placeHolderFileUrl === 'object') {
        setPreview(URL.createObjectURL(placeHolderFileUrl));
        return;
      }
      setPreview(`${config.url}/${placeHolderFileUrl}`);
    }
  }, [placeHolderFileUrl]);

  return (
    <div className="flex flex-col items-center justify-center">
      <label
        htmlFor="fileInput"
        className={`w-full px-6 py-4 bg-white rounded-xl border border-[#eaecf0] flex flex-col items-center justify-center gap-4 text-center cursor-pointer transition-colors 
          ${file ? 'bg-gray-100 border-gray-400' : ''}`}
        onDrop={handleDrop}
        onDragOver={(e) => e.preventDefault()}
      >
        {!file && !preview ? (
          <div className="w-10 h-10 p-2.5 rounded-lg shadow border border-[#eaecf0] justify-center items-center inline-flex">
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="upload-cloud-02">
                <path
                  id="Icon"
                  d="M6.6665 14.054L9.99984 10.7207M9.99984 10.7207L13.3332 14.054M9.99984 10.7207V18.2207M16.6665 14.6731C17.6844 13.8324 18.3332 12.5607 18.3332 11.1374C18.3332 8.60606 16.2811 6.55404 13.7498 6.55404C13.5677 6.55404 13.3974 6.45903 13.3049 6.30215C12.2182 4.45807 10.2119 3.2207 7.9165 3.2207C4.46472 3.2207 1.6665 6.01892 1.6665 9.4707C1.6665 11.1925 2.36271 12.7516 3.48896 13.882"
                  stroke="#344054"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </svg>
          </div>
        ) : (
          <img src={preview} alt="Uploaded file" className="w-auto h-16" />
        )}

        <p className="text-sm font-normal font-inter leading-[18px]">
          {file ? (
            <p>{file.name}</p>
          ) : error ? (
            <p className="text-red-500">{error}</p>
          ) : (
            <>
              <span className="text-[#323333] font-semibold">Click to upload</span>
              <span className="text-[#475467]"> or drag and drop</span>
              <br />
              <span>SVG, PNG, JPG or GIF (max. 1000x1000px)</span>
            </>
          )}
        </p>
      </label>
      <input
        type="file"
        id="fileInput"
        className="hidden"
        onChange={handleFileChange}
        accept=".png,.jpg,.jpeg,.gif,.svg"
      />
    </div>
  );
}

export default DragAndDropInput;
