/* eslint-disable object-curly-newline */
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useGlobal } from 'reactn';
import { toast } from 'sonner';
import editWorkspace from '../../../../actions/editWorkspace';
import getCurrentWorkspace from '../../../../actions/getCurrentWorkspace';
import { Button } from '../../../../components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../../../../components/ui/dialog';
import { Input } from '../../../../components/ui/input';
import config from '../../../../config';
import { generateColorShades } from '../../../../lib/utils';
import DragAndDropInput from '../../components/dndInput';

function Workspace() {
  const [brandColor, setBrandColor] = useState('');
  const [workspaceName, setWorkspaceName] = useState('');
  const [brandID, setBrandID] = useState('');
  const [brandLogo, setBrandLogo] = useState(null);
  const [workspaceId, setWorkspaceId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isVerifyModalOpen, setIsVerifyModalOpen] = useState(false);
  const [isVerifyLoading, setIsVerifyLoading] = useState(false);

  const [searchParams] = useSearchParams();
  const invitationCode = searchParams.get('invitationCode');

  const [colorShades, setColorShades] = useState([]);

  const navigate = useNavigate();

  const [user, setUser] = useGlobal('user');

  useEffect(() => {
    axios
      .get(`${config.url || ''}/api/v1/user/back_office/get_form`)
      .then(() => {})
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleCreateWorkSpace = async (e) => {
    e.preventDefault();
    if (!workspaceName || !workspaceId) return;
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('name', workspaceName);
      formData.append('description', workspaceName);
      if (typeof brandLogo === 'object') {
        formData.append('brandLogo', brandLogo);
      }
      formData.append('brandID', brandID);
      formData.append('brandColor', brandColor);

      const { data: res } = await editWorkspace(workspaceId, formData);
      if (res.success) {
        const { data: res } = await getCurrentWorkspace();
        setUser({ ...user, workspace: res?.data?.workspace, workspace_member: res?.data?.workspace_member });

        toast.success('Workspace updated successfully.', {
          position: 'top-center',
        });
      }
    } catch (err) {
      console.log(err);
      toast.error(err.response.data.message, {
        position: 'top-center',
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (user?.workspace) {
      setWorkspaceName(user?.workspace?.name);
      setBrandID(user?.workspace?.brandID);
      setBrandLogo(user?.workspace?.brandLogo?.location);
      setBrandColor(user?.workspace?.brandColor);
      setWorkspaceId(user?.workspace?._id);
    }
  }, [user]);

  useEffect(() => {
    if (invitationCode) {
      setIsVerifyModalOpen(true);
    }
  }, [invitationCode]);

  const handleVerifyOTP = async () => {
    if (!invitationCode) {
      toast.error('Please check your invitation code', {
        position: 'top-center',
      });
      return;
    }
    try {
      setIsVerifyLoading(true);
      const { data: res } = await axios({
        method: 'post',
        url: `${config.url || ''}/api/v1/user/workspace/accept_workspace_invitation`,
        data: {
          invitationCode,
        },
      });
      if (res.success) {
        setIsVerifyLoading(false);
        setIsVerifyModalOpen(false);
        toast.success(res.message, {
          position: 'top-center',
        });
        const { data: user } = await axios({
          method: 'get',
          url: `${config.url || ''}/api/v1/user/me`,
        });
        setUser(user.data);
        window.location.href = `${window.location.origin}/settings`;
      }
    } catch (err) {
      console.log(err);
      toast.error('Something went wrong!', {
        description: 'Please try again.',
        position: 'top-center',
      });
    }
  };

  const handleReject = async () => {
    if (!invitationCode) {
      toast.error('Please check your invitation code', {
        position: 'top-center',
      });
      return;
    }
    try {
      setIsVerifyLoading(true);
      const { data: res } = await axios({
        method: 'post',
        url: `${config.url || ''}/api/v1/user/workspace/reject_workspace_invitation`,
        data: {
          invitationCode,
        },
      });
      if (res.success) {
        setIsVerifyLoading(false);
        setIsVerifyModalOpen(false);
        toast.success(res.message, {
          position: 'top-center',
        });
        const { data: user } = await axios({
          method: 'get',
          url: `${config.url || ''}/api/v1/user/me`,
        });
        setUser(user.data);
        navigate('/settings');
      }
    } catch (err) {
      console.log(err);
      toast.error('Something went wrong!', {
        description: 'Please try again.',
        position: 'top-center',
      });
    }
  };

  const handleChangeColor = (e) => {
    e.preventDefault();
    const color = e.target.value;
    setBrandColor(color);
    const shades = generateColorShades(color);
    setColorShades(shades);
  };

  console.log(colorShades);

  return (
    <>
      <div
        className={`grid lg:grid-cols-2 grid-cols-1 gap-6 ${
          user?.workspace?.createdBy !== user?._id ? 'opacity-60 pointer-events-none' : ''
        }`}
      >
        <div className="mt-4 space-y-6">
          <div className="bg-white rounded-lg border border-slate-300">
            <hr />
            <div className="px-6 py-4 space-y-4">
              <div className="space-y-2">
                <div>
                  <h4 className="text-slate-700 text-base font-medium leading-normal">Workspace Name</h4>
                </div>
                <Input
                  placeholder="Workspace Name"
                  onChange={(e) => setWorkspaceName(e.target.value)}
                  value={workspaceName}
                />
              </div>

              <div className="space-y-2">
                <div>
                  <h4 className="text-slate-700 text-base font-medium leading-normal">Workspace ID</h4>
                </div>
                <div className="flex items-center gap-1">
                  <h4 className="text-slate-700 text-base font-medium leading-normal">@</h4>
                  <Input placeholder="Workspace ID" onChange={(e) => setBrandID(e.target.value)} value={brandID} />
                </div>
              </div>

              <div className="space-y-1.5">
                <div>
                  <h4 className="text-slate-700 text-base font-medium leading-normal">Brand Logo</h4>
                  <p className="text-slate-500 text-sm font-normal font-inter leading-tight">
                    Change the brand logo for the survey
                  </p>
                </div>
                <div className="my-3 flex items-start gap-4">
                  {/* {brandLogo && (
                    <div>
                      <img src={`${config.url}/${brandLogo}`} alt="Brand Logo" className="w-20 h-20 rounded-full" />
                    </div>
                  )} */}

                  <div className="w-full">
                    <DragAndDropInput callback={(imageObject) => setBrandLogo(imageObject)} placeHolderFileUrl={brandLogo} />
                  </div>
                </div>
              </div>

              <div className="space-y-2">
                <div>
                  <h4 className="text-slate-700 text-base font-medium leading-normal">Brand Color</h4>
                  <p className="text-slate-500 text-sm font-normal font-inter leading-tight">
                    Change the brand color for the survey
                  </p>
                </div>
                <label
                  htmlFor="brand-color"
                  className="rounded-md border border-slate-200 bg-white px-3 py-2 flex justify-between items-center gap-2.5"
                >
                  <span className="text-[#64748B] text-base font-normal">
                    {brandColor?.replace('#', '#  ') || 'Brand color'}
                  </span>
                  <Input
                    className="p-0 border-none ring-0 outline-none bg-transparent w-10 h-6"
                    id="brand-color"
                    type="color"
                    value={brandColor}
                    onChange={handleChangeColor}
                  />
                </label>

                <p>
                  {colorShades?.name}
                </p>
                <div className="grid grid-cols-10 border">
                  {
                    colorShades?.shades?.map((shade) => (
                      <div
                        key={shade.name}
                        className="p-2 h-[40px] flex items-center gap-0.5 justify-center text-xs font-bold text-gray-300"
                        style={{
                          backgroundColor: shade.hexcode,
                        }}
                      >
                        {shade.name}
                        {shade.hexcode === brandColor && (
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-3">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z" />
                        </svg>
                        )}
                      </div>
                    ))
                  }
                </div>
              </div>

              {user?.workspace?.createdBy === user?._id && (
                <div className="flex gap-4">
                  {/* <Button className="bg-white hover:bg-gray-50 text-slate-700 border">
              Cancel
            </Button> */}
                  <Button onClick={handleCreateWorkSpace}>{isLoading ? 'Saving...' : 'Save'}</Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Dialog open={isVerifyModalOpen} onOpenChange={setIsVerifyModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Accept Invitation.</DialogTitle>
          </DialogHeader>
          <div className="space-y-3 flex flex-col w-full mt-4">
            <Button onClick={handleVerifyOTP} className="flex items-center gap-1">
              Accept
              {isVerifyLoading && (
                <span className="size-2.5 border border-white border-t-transparent ml-1 animate-spin rounded-full block" />
              )}
            </Button>
            <Button className="bg-white hover:bg-gray-50 text-slate-700 border" onClick={handleReject}>
              Cancel
              {isVerifyLoading && (
                <span className="size-2.5 border border-black border-t-transparent ml-1 animate-spin rounded-full block" />
              )}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default Workspace;
